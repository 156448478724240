import { Button, Card, Stack, Text, styled } from "@hub-la/design-system"
import CreditCardIcon from "@mui/icons-material/CreditCard"
import PixIcon from "@mui/icons-material/Pix"
import { CardDetail } from "modules/user-subscriptions/domain/dtos/card-detail"
import { CardBrand } from "modules/user-subscriptions/domain/enums/card-brand"
import { PaymentMethod } from "modules/user-subscriptions/domain/enums/payment-method"
import React, { useState } from "react"
import amex from "../../../assets/amex.png"
import elo from "../../../assets/elo.png"
import mastercard from "../../../assets/mastercard.png"
import visa from "../../../assets/visa.png"
import { ChangePaymentMethodModal } from "./change-payment-method-modal"
import i18n from "i18n"

type Props = {
  paymentMethod?: PaymentMethod
  cardDetails?: CardDetail
  subscriptionId: string
  offerId: string
}

const CardIcon = styled("img")`
  width: 30px;
  height: auto;
  object-fit: contain;
  object-position: center;
`

const getIcon = (paymentMethod?: PaymentMethod, cardBrand?: CardBrand) => {
  if (paymentMethod === PaymentMethod.PIX) return <PixIcon />
  switch (cardBrand) {
    case CardBrand.CARD_BRAND_VISA:
      return <CardIcon src={visa} aria-label={visa} />
    case CardBrand.CARD_BRAND_MASTERCARD:
      return <CardIcon src={mastercard} aria-label={mastercard} />
    case CardBrand.CARD_BRAND_AMEX:
      return <CardIcon src={amex} aria-label={amex} />
    case CardBrand.CARD_BRAND_ELO:
      return <CardIcon src={elo} aria-label={elo} />
    default:
      return <CreditCardIcon />
  }
}

const getText = (paymentMethod?: PaymentMethod, cardDetails?: CardDetail) => {
  if (paymentMethod === PaymentMethod.PIX) return "Pix"
  if (paymentMethod === PaymentMethod.CREDIT_CARD) {
    return cardDetails
      ? `${i18n.t(`userSubscriptions.cardBrand.${cardDetails?.brand}`)} •••• ${cardDetails?.last4}`
      : "Cartão de crédito"
  }
  return ""
}

export const PaymentDetails: React.FC<Props> = (props) => {
  const { paymentMethod, cardDetails, subscriptionId, offerId } = props
  const [open, setOpen] = useState<boolean>(false)

  return (
    <Card variant="filled" hasHover={false} fullHeight>
      <Stack direction="column" justifyContent="space-between" height="100%" gap={4}>
        <Stack direction="column" width="100%">
          <Text variant="body1">Método de pagamento</Text>
          <Stack direction="row" gap={2}>
            {getIcon(paymentMethod, cardDetails?.brand)}
            <Text variant="body1" color="onSurfaceVariant">
              {getText(paymentMethod, cardDetails)}
            </Text>
          </Stack>
        </Stack>
        <Button variant="outlined" hierarchy="secondary" fullWidth onClick={() => setOpen(true)}>
          Alterar método de pagamento
        </Button>
      </Stack>
      <ChangePaymentMethodModal
        open={open}
        onClose={() => setOpen(false)}
        subscriptionId={subscriptionId}
        offerId={offerId}
      />
    </Card>
  )
}
