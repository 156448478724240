import React from "react"
import { Pagination, Stack, Table, TableCell, TableRow, Text } from "@hub-la/design-system"
import CreditCardIcon from "@mui/icons-material/CreditCard"
import PixIcon from "@mui/icons-material/Pix"
import isEmpty from "lodash/isEmpty"
import times from "lodash/times"
import moment from "moment"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Invoice } from "../../../../domain/dtos/invoice"
import { PaymentMethod } from "../../../../domain/enums/payment-method"
import { formatCurrency } from "../../../../domain/vos/format-currency"
import { useCheckHaveChanged } from "../../../hooks/use-check-have-changed"
import { useGetInvoices } from "../../../hooks/use-get-invoices"
import { InvoiceStatusStats } from "./invoice-status-stats"
import { InvoiceTableLoading } from "./invoice-table-loading"
import { useHistory } from "react-router-dom"
import { routes } from "@chatpay/common"
import { SmartInstallmentChip } from "./smart-installment-chip"

export const InvoiceTable = ({ offerId }) => {
  const [page, setPage] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(25)
  const { t } = useTranslation()
  const {
    data = { items: [], total: 0, page, pageSize: pageSize, lastPage: 1 },
    isFetching,
    refetch,
  } = useGetInvoices({
    offerId: offerId,
    page: page,
    pageSize: pageSize,
  })
  const history = useHistory()

  const getHeadCells = () => {
    return [
      { key: "total", label: t("userSubscriptions.table.total"), sortable: false, width: 90 },
      { key: "status", label: t("userSubscriptions.table.status"), sortable: false, width: 70 },
      { key: "paidAt", label: t("userSubscriptions.table.paidAt"), sortable: false, width: 110 },
    ]
  }

  const canRefetch = useCheckHaveChanged({ page, pageSize })
  useEffect(() => {
    canRefetch && refetch()
  }, [canRefetch, refetch])

  const getIcon = (paymentMethod: PaymentMethod) => {
    switch (paymentMethod) {
      case PaymentMethod.PIX:
        return <PixIcon />
      case PaymentMethod.CREDIT_CARD:
        return <CreditCardIcon />
      default:
        // eslint-disable-next-line react/jsx-no-useless-fragment
        return <></>
    }
  }

  const isRowsEmpty = isEmpty(data?.items)
  const canRenderEmptyState = !isFetching && isRowsEmpty

  const redirectToInvoiceDetail = (id: string) => history.push(routes.USER_INVOICES.LINK + "/" + id)

  return (
    <Stack height="100%" width="100%" mt={8}>
      <Text variant="h4" mb={3}>
        {t("userSubscriptions.invoiceHistory")}
      </Text>
      <Stack flexDirection="column" gap={4}>
        <Table
          id="table"
          maxHeight="100%"
          headCells={getHeadCells()}
          bgHeadCell="#121212"
          minWidth={700}
          empty={canRenderEmptyState}
        >
          {isFetching
            ? times(3, (idx) => <InvoiceTableLoading key={`skeleton-row-${idx}`} />)
            : (data?.items ?? []).map((invoice: Invoice) => {
                return (
                  <TableRow
                    key={invoice.id}
                    onClick={() => redirectToInvoiceDetail(invoice.id)}
                    style={{ cursor: "pointer" }}
                  >
                    <TableCell density="compact" padding="none" scope="row">
                      <Stack direction="row" alignItems="center" gap={2}>
                        {getIcon(invoice.paymentMethod)}
                        <Text variant="body1">{formatCurrency(invoice.totalCents / 100)}</Text>
                      </Stack>
                    </TableCell>
                    <TableCell density="compact" padding="none" scope="row">
                      <Stack direction="row" gap={1}>
                        <InvoiceStatusStats status={invoice.status} size="tiny" />
                        {invoice.smartInstallmentRef && (
                          <SmartInstallmentChip
                            status={invoice.smartInstallmentRef.status}
                            size="tiny"
                            installment={invoice.smartInstallmentRef.installment}
                            installments={invoice.smartInstallmentRef.installments}
                          />
                        )}
                      </Stack>
                    </TableCell>
                    <TableCell density="compact" padding="none" scope="row">
                      <Text color="outline" variant="body1" fontWeight="lighter">
                        {invoice.paidAt ? moment(invoice.paidAt).format("D MMM. YYYY") : "-"}
                      </Text>
                    </TableCell>
                  </TableRow>
                )
              })}
        </Table>

        <Pagination
          page={page}
          pageSize={pageSize}
          total={data.total}
          lastPage={data.lastPage}
          onChangePage={(page) => setPage(page)}
          onChangePageSize={(size) => setPageSize(size)}
        />
      </Stack>
    </Stack>
  )
}
