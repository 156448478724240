import { Card, Stack, Text, styled } from "@hub-la/design-system"
import CreditCardIcon from "@mui/icons-material/CreditCard"
import PixIcon from "@mui/icons-material/Pix"
import { CardDetail } from "modules/user-subscriptions/domain/dtos/card-detail"
import { CardBrand } from "modules/user-subscriptions/domain/enums/card-brand"
import { PaymentMethod } from "modules/user-subscriptions/domain/enums/payment-method"
import React from "react"
import amex from "../../../assets/brands/amex.png"
import elo from "../../../assets/brands/elo.png"
import mastercard from "../../../assets/brands/mastercard.png"
import visa from "../../../assets/brands/visa.png"
import i18n from "i18n"

type Props = {
  paymentMethod?: PaymentMethod
  cardDetails?: CardDetail
  softDescriptor?: string
}

const CardIcon = styled("img")`
  width: 30px;
  height: auto;
  object-fit: contain;
  object-position: center;
`

const getIcon = (paymentMethod?: PaymentMethod, cardBrand?: CardBrand) => {
  if (paymentMethod === PaymentMethod.PIX) return <PixIcon />
  switch (cardBrand) {
    case CardBrand.CARD_BRAND_VISA:
      return <CardIcon src={visa} aria-label={visa} />
    case CardBrand.CARD_BRAND_MASTERCARD:
      return <CardIcon src={mastercard} aria-label={mastercard} />
    case CardBrand.CARD_BRAND_AMEX:
      return <CardIcon src={amex} aria-label={amex} />
    case CardBrand.CARD_BRAND_ELO:
      return <CardIcon src={elo} aria-label={elo} />
    default:
      return <CreditCardIcon />
  }
}

const getText = (paymentMethod?: PaymentMethod, cardDetails?: CardDetail) => {
  if (paymentMethod === PaymentMethod.PIX) return "Pix"
  if (paymentMethod === PaymentMethod.CREDIT_CARD) {
    return cardDetails
      ? `${i18n.t(`userInvoices.cardBrand.${cardDetails?.brand}`)} •••• ${cardDetails?.last4}`
      : "Cartão de crédito"
  }
  return ""
}

export const PaymentDetails: React.FC<Props> = (props) => {
  const { paymentMethod, cardDetails, softDescriptor } = props

  return (
    <Card variant="outlined" hasHover={false} fullHeight>
      <Stack direction="column" gap={2}>
        <Stack direction="column" width="100%">
          <Text variant="body1" color="onSurface">
            Método de pagamento
          </Text>
          <Stack direction="row" gap={2}>
            {getIcon(paymentMethod, cardDetails?.brand)}
            <Text variant="body1" color="outline">
              {getText(paymentMethod, cardDetails)}
            </Text>
          </Stack>
        </Stack>
        {softDescriptor && (
          <Stack direction="column" width="100%">
            <Text variant="body1" color="onSurface">
              Descrição na fatura do cartão
            </Text>
            <Text variant="body1" color="outline">
              {softDescriptor}
            </Text>
          </Stack>
        )}
      </Stack>
    </Card>
  )
}
