import { AnyAction } from "redux"
import { ThunkAction } from "redux-thunk"
import { API } from "@chatpay/components"
import { Document, Interface } from "@chatpay/common"
import { getPayCheckoutUrl, getSiteUrl } from "utils/SessionUtils"

export interface ILink {
  name: string
  url: string
}

export enum GetAffiliateActionTypes {
  LOAD = "GET_AFFILIATE_LOAD",
  LOAD_SUCCESS = "GET_AFFILIATE_LOAD_SUCCESS",
  LOAD_FAILURE = "GET_AFFILIATE_LOAD_FAILURE",
}

export interface ILoadAction {
  type: GetAffiliateActionTypes.LOAD
  group: Document.Group
}

export interface ILoadSuccessAction {
  type: GetAffiliateActionTypes.LOAD_SUCCESS
  links: ILink[]
  hasAffiliate: boolean
}

export interface ILoadFailureAction {
  type: GetAffiliateActionTypes.LOAD_FAILURE
  reason: string
}

export const loadGetAffiliate =
  (group: Document.Group): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
  async (dispatch) => {
    dispatch({ type: GetAffiliateActionTypes.LOAD, group })

    const [response, groupLinks] = await Promise.all([
      new API.Affiliate()
        .getLink({ type: "Group", objectId: group?.id! })
        .catch((error) => dispatch(loadFailure(error))),
      new API.Group().getShortLinks({ groupId: group.id }),
    ])

    const affiliateId = (response as Interface.Affiliate.Function.ILink).id

    if (affiliateId) {
      const finalLinks: ILink[] = [
        {
          name: "Página do produto",
          url: `${getSiteUrl()}g/${group.id}?ref=${affiliateId}`,
        },
        { name: "Checkout", url: `${getPayCheckoutUrl()}/${group.id}?ref=${affiliateId}` },
      ].concat(
        (groupLinks ?? []).map((link) => {
          return { name: link.data?.name, url: getSiteUrl("r/" + link.id) + "?ref=" + affiliateId }
        }),
      )
      dispatch(loadSuccess(finalLinks, !!response))
    }
  }

export const loadSuccess = (links: ILink[], hasAffiliate: boolean): ILoadSuccessAction => {
  return {
    type: GetAffiliateActionTypes.LOAD_SUCCESS,
    links,
    hasAffiliate,
  }
}

export const loadFailure = (reason: string): ILoadFailureAction => {
  return {
    type: GetAffiliateActionTypes.LOAD_FAILURE,
    reason,
  }
}

export type GetAffiliateAction = ILoadAction | ILoadSuccessAction | ILoadFailureAction
