import { useContainer } from "@hub-la/fe-container"
import { HttpClient } from "@hub-la/fe-core-http-client"
import { useQuery } from "react-query"
import { GetOffers } from "../../usecases/get-offers"
import { QueryKey } from "modules/user-invoices/domain/enums/query-key"

export const useGetOffers = () => {
  const container = useContainer()

  return useQuery([QueryKey.getOffers], () => new GetOffers(container.get(HttpClient)).execute(), {
    retry: false,
    retryOnMount: false,
    refetchOnWindowFocus: false,
    initialData: [],
  })
}
