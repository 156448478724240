import React, { useEffect } from "react"
import { DropdownItemProps } from "semantic-ui-react"
import { useTranslation } from "react-i18next"
import { Spacer } from "components"
import {
  FeedbackBanner,
  MenuItem,
  Select,
  Stack,
  Switch,
  Text,
  Radio,
  RadioGroup,
  withUtility,
  shape,
  css,
  Divider,
  styled,
  Checkbox,
} from "@hub-la/design-system"
import { FormControl } from "@mui/material"
import Stream from "@mui/icons-material/Stream"
import { Interval } from "@chatpay/common/lib/Documents/Plan"

const StyledStack = styled(Stack)`
  border-radius: ${shape("medium")};
  ${withUtility(({ palette }) => {
    return css`
      border: 1px solid ${palette("surfaceAt3")};
    `
  })}
`
interface ISmartInstallment {
  isEnabled: boolean
  shouldActivateWhenOffSchedule: boolean
  plans: Partial<{
    quarterly: {
      isEnabled: boolean
      maxInstallments: number
    }
    semiannually: {
      isEnabled: boolean
      maxInstallments: number
    }
    annually: {
      isEnabled: boolean
      maxInstallments: number
    }
    oneTime: {
      isEnabled: boolean
      maxInstallments: number
    }
  }>
}

export function SmartInstallments(props: {
  data: Partial<ISmartInstallment> | undefined
  setSmartInstallmentParams: React.Dispatch<React.SetStateAction<ISmartInstallment | undefined>>
  isSubscription: boolean
  onSave: (data) => Promise<void>
}) {
  const { t } = useTranslation()
  const { data, setSmartInstallmentParams, isSubscription, onSave } = props
  const maxInstallmentsByInterval: Record<Interval | "oneTime", number> = {
    [Interval.monthly]: 1,
    [Interval.quarterly]: 3,
    [Interval.semiannually]: 6,
    [Interval.annually]: 12,
    oneTime: 12,
  }

  const getInstallmentOptions = (interval: Interval): DropdownItemProps[] => {
    const maxInstallments = maxInstallmentsByInterval[interval]
    const inst: DropdownItemProps[] = []
    for (let i = 2; i <= maxInstallments; i++) {
      inst.push({ key: i, text: t("GroupForm.entryFee.installments.upTo") + ` ${i}x`, value: i })
    }
    return inst
  }

  const onInstallmentUpdate = (interval: Interval, value: string) => {
    setSmartInstallmentParams((payload) => {
      if (payload) {
        payload.plans[interval].maxInstallments = parseInt(value)
      }
      const data = {
        ...payload,
        plans: {
          ...payload?.plans,
          [interval]: {
            ...payload?.plans[interval],
            maxInstallments: parseInt(value),
          },
        },
      } as any
      onSave(data)
      return data
    })
  }

  const onChangeCheckSmartInstallments = (interval: Interval, checked: boolean) => {
    setSmartInstallmentParams((payload) => {
      if (payload) {
        const data = {
          ...payload,
          plans: {
            ...payload.plans,
            [interval]: {
              ...payload.plans[interval],
              isEnabled: checked,
            },
          },
        }
        onSave(data)
        return data
      }
    })
  }

  const setShouldActivateWhenOffSchedule = (value: boolean) => {
    setSmartInstallmentParams((payload: any) => {
      const data = {
        ...payload,
        shouldActivateWhenOffSchedule: value,
      }
      onSave(data)
      return data
    })
  }

  const onChangeSmartInstallmentCheck = (checked: boolean) => {
    setSmartInstallmentParams((payload: ISmartInstallment | undefined) => {
      if (payload) {
        const data = {
          ...payload,
          isEnabled: checked,
        }
        onSave(data)
        return data
      }
    })
  }

  return (
    <Stack>
      <Switch
        checked={data?.isEnabled}
        onChange={(event) => onChangeSmartInstallmentCheck(event.target.checked)}
        label={t("OffersPane.SmartInstallment.title")}
        direction="left"
        ml={1}
        mb={3}
      />
      {data?.isEnabled && (
        <>
          <Spacer />
          <Text variant="body2" color="onSurfaceVariant">
            {t("OffersPane.SmartInstallment.description")}
          </Text>

          <Stack>
            <FormControl fullWidth>
              <Spacer />
              <StyledStack>
                {Object.entries(data?.plans ?? {})
                  .filter(
                    (plan) => (isSubscription && plan[0] !== "oneTime") || (!isSubscription && plan[0] === "oneTime"),
                  )
                  .map(([key, value], index) => (
                    <React.Fragment key={index}>
                      <Stack padding={2}>
                        {isSubscription && (
                          <Checkbox
                            label={t("OffersPane.SmartInstallment.subscription." + key)}
                            marginLeft={2}
                            checked={value.isEnabled}
                            onChange={(_event, checked) =>
                              onChangeCheckSmartInstallments(key as unknown as Interval, checked)
                            }
                          />
                        )}
                        {value.isEnabled && (
                          <Select
                            marginTop={4}
                            value={value?.maxInstallments ?? 2}
                            name="installments"
                            label={t("OffersPane.SmartInstallment.allowOnPurchasesOf")}
                            onChange={(event) =>
                              onInstallmentUpdate(key as unknown as Interval, event.target?.value as string)
                            }
                          >
                            {getInstallmentOptions(key as unknown as any).map((opt) => (
                              <MenuItem key={opt.key} value={opt?.value?.toString() ?? ""}>
                                {opt.text}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      </Stack>
                      <Divider />
                    </React.Fragment>
                  ))}
              </StyledStack>
              <Spacer />
              <FeedbackBanner divider="layout" variant="neutral" icon={<Stream />}>
                <Text variant="body2" color="onSurfaceVariant">
                  {t("OffersPane.SmartInstallment.banner")}
                </Text>
              </FeedbackBanner>
              <Spacer />
              <Text variant="body1" color="onSurfaceVariant">
                {t("OffersPane.SmartInstallment.preferences.title")}
              </Text>

              <Text variant="body2" color="onSurfaceVariant">
                {t("OffersPane.SmartInstallment.preferences.description")}
              </Text>

              <Spacer />
              <RadioGroup id="shouldActivateWhenOffSchedule" label="">
                <StyledStack>
                  <Stack padding={2}>
                    <Radio
                      label={t("OffersPane.SmartInstallment.shouldActivateWhenOffSchedule.true.title")}
                      labelPlacement="end"
                      checked={data?.shouldActivateWhenOffSchedule}
                      onClick={() => setShouldActivateWhenOffSchedule(true)}
                    />
                    <Text variant="body2" color="onSurfaceVariant">
                      {t("OffersPane.SmartInstallment.shouldActivateWhenOffSchedule.true.description")}
                    </Text>
                  </Stack>
                  <Divider />
                  <Stack padding={2}>
                    <Radio
                      label={t("OffersPane.SmartInstallment.shouldActivateWhenOffSchedule.false.title")}
                      labelPlacement="end"
                      checked={!data?.shouldActivateWhenOffSchedule}
                      onClick={() => setShouldActivateWhenOffSchedule(false)}
                    />
                    <Text variant="body2" color="onSurfaceVariant">
                      {t("OffersPane.SmartInstallment.shouldActivateWhenOffSchedule.false.description")}
                    </Text>
                  </Stack>
                </StyledStack>
              </RadioGroup>
            </FormControl>
          </Stack>
        </>
      )}
    </Stack>
  )
}
