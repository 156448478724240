import { Button, Card, Stack, Text } from "@hub-la/design-system"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { UpgradePlanModal } from "./upgrade-plan-modal"
import { SubscriptionStatus } from "modules/user-subscriptions/domain/enums/subscription-status"
import { useGetSubscriptionValue } from "modules/user-subscriptions/presentation/hooks/use-get-subscription-value"
import { Skeleton } from "@mui/material"
import { formatCurrencyWithInstallments } from "modules/user-subscriptions/domain/vos/format-currency"

type Props = {
  planType: string
  subscriptionId: string
  status: SubscriptionStatus
}

export const PlanDetails: React.FC<Props> = (props) => {
  const { planType, subscriptionId, status } = props
  const { t } = useTranslation()
  const { data: value } = useGetSubscriptionValue(subscriptionId)
  const [open, setOpen] = useState<boolean>(false)
  const canUpgradePlan = [SubscriptionStatus.ACTIVE].includes(status)

  return (
    <Card variant="filled" hasHover={false} fullHeight>
      <Stack direction="column" gap={4}>
        <Stack direction="column" width="100%">
          <Text variant="body1">{t("userSubscriptions.planDetails.title")}</Text>
          <Text variant="body1" color="onSurfaceVariant">
            {t(`userSubscriptions.planType.${planType}`)}
          </Text>
        </Stack>
        <Stack direction="column" width="100%">
          <Text variant="body1">{t("userSubscriptions.planDetails.price")}</Text>
          <Text variant="body1" color="onSurfaceVariant">
            {value ? (
              formatCurrencyWithInstallments(
                value.installmentsAmount,
                value.totalInCents / 100,
                value.installmentsPriceInCents / 100,
              )
            ) : (
              <Skeleton width={70} animation="wave" />
            )}
          </Text>
        </Stack>
        {canUpgradePlan && (
          <Button variant="outlined" hierarchy="secondary" fullWidth onClick={() => setOpen(true)}>
            {t("userSubscriptions.planDetails.upgrade")}
          </Button>
        )}
      </Stack>
      <UpgradePlanModal open={open} onClose={() => setOpen(false)} subscriptionId={subscriptionId} />
    </Card>
  )
}
