import { Document, Interface, Type } from "@chatpay/common"
import API from "./API"
import { DB, Firebase } from "../Service"

export type UserProfile = { user: Document.User; groups: Document.Group[]; members: { [x: string]: Document.Member } }

class User extends API implements Interface.User.Function.ITemplate {
  private userBucketDb = new DB(Document.UserBuckets)

  public async get(data: Interface.User.Function.IGet): Promise<Document.User | null> {
    const response = await this.call(Interface.User.Function.Name.get, data)
    return response.data ? new Document.User(response.data) : null
  }

  public async getUserProfile(data: Interface.User.Function.IGet): Promise<UserProfile | null> {
    const response = await this.call(Interface.User.Function.Name.getUserProfile, data)
    const responseData: Interface.User.Function.UserProfileResponse | null = response.data
    if (!responseData) {
      return null
    }

    const user = new Document.User(responseData.user)
    const groups = responseData.groups.map((it: Partial<Document.Group>) => new Document.Group({ ...it, owner: user }))
    const members = Object.keys(responseData.members).reduce((acc, item) => {
      acc[item] = new Document.Member(responseData.members[item])
      return acc
    }, {} as { [x: string]: Document.Member })

    return { user, groups, members }
  }

  public async getReferredUsers(): Promise<Document.User[]> {
    const response = await this.call(Interface.User.Function.Name.getReferredUsers)
    return response.data
  }

  public async registerHubspotContact(): Promise<Document.User> {
    return new Document.User((await this.call(Interface.User.Function.Name.registerHubspotContact)).data)
  }

  public async setCountry(data: Interface.User.Function.ISetCountry): Promise<Document.User> {
    const response = await this.call(Interface.User.Function.Name.setCountry, data)
    return new Document.User(response.data)
  }

  public async setPicture(data: Interface.User.Function.ISetPicture): Promise<Document.User> {
    const response = await this.call(Interface.User.Function.Name.setPicture, data)
    return new Document.User(response.data)
  }

  public async setCurrentStep(data: Interface.User.Function.ISetCurrentStep): Promise<Document.User> {
    return new Document.User((await this.call(Interface.User.Function.Name.setCurrentStep, data)).data)
  }

  public async setBuckets(data: Interface.User.Function.ISetBuckets): Promise<Document.UserBuckets> {
    return new Document.UserBuckets((await this.call(Interface.User.Function.Name.setBuckets, data)).data)
  }

  public async getUserBucket(): Promise<Document.UserBuckets | null> {
    return this.userBucketDb.getFirst({
      where: {
        field: "userId",
        op: "==",
        value: Firebase.currentUser?.id,
      },
    })
  }

  public async balance(): Promise<Type.Balance.Balance[]> {
    return (await this.call(Interface.User.Function.Name.balance)).data
  }

  public async update(data: Interface.User.Function.IUpdate): Promise<Document.User> {
    return new Document.User((await this.call(Interface.User.Function.Name.update, data)).data)
  }

  public async adminUpdate(data: Interface.User.Function.IUpdate): Promise<Document.User> {
    return new Document.User((await this.call(Interface.User.Function.Name.adminUpdate, data)).data)
  }

  public async updateWppId(data: Interface.User.Function.IUpdateWppId): Promise<Document.User> {
    return new Document.User((await this.call(Interface.User.Function.Name.updateWppId, data)).data)
  }

  public async connectTelegram(data: Interface.User.Function.IConnectTelegram): Promise<Document.User> {
    return new Document.User((await this.call(Interface.User.Function.Name.connectTelegram, data)).data)
  }

  public async telegramCode(): Promise<string> {
    const telegramCode = Firebase.currentUser?.telegramCode
    if (telegramCode) {
      return telegramCode
    }
    const result = await this.call(Interface.User.Function.Name.telegramCode)
    await new Promise((resolve) => setTimeout(resolve, 5000))
    return result.data as string
  }

  public async acceptTerms(): Promise<Document.User> {
    return new Document.User((await this.call(Interface.User.Function.Name.acceptTerms)).data)
  }

  public async usernameGenerate() {
    return (await this.call(Interface.User.Function.Name.usernameGenerate)).data
  }

  public async usernameValidate(data: Interface.User.Function.IUsernameValidate) {
    return (await this.call(Interface.User.Function.Name.usernameValidate, data)).data
  }

  public async generateTelegramCode(
    data?: Interface.User.Function.IGenerateTelegramCode,
  ): Promise<Interface.User.Function.ITelegramCode> {
    return (await this.call(Interface.User.Function.Name.generateTelegramCode, data)).data
  }

  public async updateVerificationTelegramStep(
    data: Interface.User.Function.IUpdateVerificationTelegramStep,
  ): Promise<Document.User> {
    return (await this.call(Interface.User.Function.Name.updateVerificationTelegramStep, data)).data
  }

  private async call(func: Interface.User.Function.Name, params?: Interface.User.Function.Params) {
    return await this.callFunction(`user/${func}`, params)
  }

  public getByUsername(username: string) {
    return this.get({ idOrUsername: username })
  }

  public getById(id: string) {
    return this.get({ idOrUsername: id })
  }
}

export default User
