import { useContainer } from "@hub-la/fe-container"
import { HttpClient } from "@hub-la/fe-core-http-client"
import { useQuery } from "react-query"
import { ListMemberGroups } from "../../usecases/list-member-groups"
import { QueryKey } from "modules/user-groups/domain/enums/query-key"

export const useListMemberGroups = () => {
  const container = useContainer()

  return useQuery([QueryKey.listMemberGroups], () => new ListMemberGroups(container.get(HttpClient)).execute(), {
    retry: false,
    retryOnMount: false,
    refetchOnWindowFocus: false,
    initialData: [],
  })
}
