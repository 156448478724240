import React from "react"
import { Box, Grid, Snackbar } from "@hub-la/design-system"
import isEmpty from "lodash/isEmpty"
import { useTranslation } from "react-i18next"
import { useListMemberGroups } from "../../hooks/use-list-member-groups"
import { Group } from "./components/group"
import { Header } from "./components/header"
import { Pendencies } from "./components/pendencies"
import { PendingInvites } from "./components/pending-invites"
import { UserDoesNotHaveGroup } from "./components/user-does-not-have-group"
import { Loading } from "./loading"
import { FLAG, useFlags } from "hooks"
import { Service } from "@chatpay/components"

const UserGroups: React.FC = () => {
  const { data = [], error, isFetching } = useListMemberGroups()
  const { t } = useTranslation()
  const isDataEmpty = isEmpty(data)
  const canRenderEmptyState = !isFetching && isDataEmpty

  const userId = Service.Firebase.currentUser?.id
  const { isEnabledByFlag } = useFlags()
  const isPendingInvitesEnabled = isEnabledByFlag(FLAG.USER_GROUPS.IS_PENDING_INVITES_ENABLED, { id: userId })

  if (isFetching && isDataEmpty) {
    return <Loading />
  }

  return (
    <Box pb={10} px={[6, 6, 6, 0]} maxWidth={946} width="100%" margin="auto">
      <Pendencies />
      <Header />
      {isPendingInvitesEnabled && <PendingInvites />}
      {canRenderEmptyState && <UserDoesNotHaveGroup />}
      {!canRenderEmptyState && (
        <Grid container spacing={[4, 6]}>
          {data.map((data) => (
            <Grid item xs={6} md={3} key={data.id}>
              <Group id={data.id} name={data.name} picture={data.pictureUrl} />
            </Grid>
          ))}
        </Grid>
      )}
      <Snackbar open={!!error} variant="negative" closeable={false}>
        {t((error as Error)?.message) ?? ""}
      </Snackbar>
    </Box>
  )
}

export default UserGroups
