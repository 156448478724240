import { Button, Card, Stack, Text, shape, spacing, styled } from "@hub-la/design-system"
import { CopyClipboard } from "@hub-la/fe-copy-clipboard"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import ContentCopyIcon from "@mui/icons-material/ContentCopy"
import CreditCardIcon from "@mui/icons-material/CreditCard"
import PixIcon from "@mui/icons-material/Pix"
import { Alert, Snackbar } from "@mui/material"
import i18n from "i18n"
import { CardDetail } from "modules/user-subscriptions/domain/dtos/card-detail"
import { CardBrand } from "modules/user-subscriptions/domain/enums/card-brand"
import { PaymentMethod } from "modules/user-subscriptions/domain/enums/payment-method"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import amex from "../../../assets/brands/amex.png"
import elo from "../../../assets/brands/elo.png"
import mastercard from "../../../assets/brands/mastercard.png"
import visa from "../../../assets/brands/visa.png"
import { InvoiceStatus } from "modules/user-invoices/domain/enums/invoice-status"

type Props = {
  paymentMethod?: PaymentMethod
  cardDetails?: CardDetail
  softDescriptor?: string
  qrcodeData?: string
  qrcodeText?: string
  status: InvoiceStatus
}

const CardIcon = styled("img")`
  width: 30px;
  height: auto;
  object-fit: contain;
  object-position: center;
`

const QrCode = styled("img")(() => ({
  maxWidth: 160,
  maxHeight: 160,
  padding: spacing(3),
  border: "1px solid rgba(0, 0, 0, 0.23)",
  borderRadius: shape("small"),
  alignSelf: "center",
  backgroundColor: "white",
}))

const getIcon = (paymentMethod?: PaymentMethod, cardBrand?: CardBrand) => {
  if (paymentMethod === PaymentMethod.PIX) return <PixIcon />
  switch (cardBrand) {
    case CardBrand.CARD_BRAND_VISA:
      return <CardIcon src={visa} aria-label={visa} />
    case CardBrand.CARD_BRAND_MASTERCARD:
      return <CardIcon src={mastercard} aria-label={mastercard} />
    case CardBrand.CARD_BRAND_AMEX:
      return <CardIcon src={amex} aria-label={amex} />
    case CardBrand.CARD_BRAND_ELO:
      return <CardIcon src={elo} aria-label={elo} />
    default:
      return <CreditCardIcon />
  }
}

const getText = (paymentMethod?: PaymentMethod, cardDetails?: CardDetail) => {
  if (paymentMethod === PaymentMethod.PIX) return "Pix"
  if (paymentMethod === PaymentMethod.CREDIT_CARD) {
    return cardDetails
      ? `${i18n.t(`userInvoices.cardBrand.${cardDetails?.brand}`)} •••• ${cardDetails?.last4}`
      : "Cartão de crédito"
  }
  return ""
}

/** This should ideally be done on backend, just like the checkout does */
const parseImageString = (imageString?: string): string => {
  if (!imageString) return ""
  if (imageString.startsWith("http") || imageString.startsWith("https")) {
    return imageString
  } else {
    return `data:image;base64,${imageString}`
  }
}

export const PaymentDetails: React.FC<Props> = (props) => {
  const { paymentMethod, cardDetails, softDescriptor, qrcodeData, qrcodeText, status } = props
  const [isOpen, setIsOpen] = useState(false)
  const { t } = useTranslation()
  const shouldRenderQrCode = status === InvoiceStatus.UNPAID && qrcodeData && qrcodeText

  const handleCopyClipboard = (code: string) => new CopyClipboard().execute(code).then(() => setIsOpen(true))

  return (
    <Card variant="filled" hasHover={false} fullHeight>
      <Stack direction="column" gap={4}>
        <Stack direction="column">
          <Text variant="body1" fontWeight="bold">
            Método de pagamento
          </Text>
          <Stack direction="row" gap={2}>
            {getIcon(paymentMethod, cardDetails?.brand)}
            <Text variant="body1" color="outline">
              {getText(paymentMethod, cardDetails)}
            </Text>
          </Stack>
        </Stack>

        {softDescriptor && (
          <Stack direction="column">
            <Text variant="body1" fontWeight="bold">
              Descrição na fatura do cartão
            </Text>
            <Text variant="body1" color="outline">
              {softDescriptor}
            </Text>
          </Stack>
        )}

        {shouldRenderQrCode && (
          <Stack direction="column" gap={6} textAlign="center">
            <QrCode src={parseImageString(qrcodeData)} alt="QR code pix" />
            <Button
              hierarchy="secondary"
              fullWidth
              onClick={() => handleCopyClipboard(qrcodeText!)}
              startIcon={<ContentCopyIcon />}
            >
              {t("userInvoices.pix.copyCode")}
            </Button>
          </Stack>
        )}
      </Stack>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={isOpen}
        onClose={() => setIsOpen(false)}
        autoHideDuration={3000}
      >
        <Alert severity="success" icon={<CheckCircleIcon />} onClose={() => setIsOpen(false)}>
          {t("userInvoices.pix.codeCopiedSuccessfully")}
        </Alert>
      </Snackbar>
    </Card>
  )
}
