import { Document } from "@chatpay/common"
import GroupInfo from "components/GroupInfo"
import * as React from "react"
import { getGroupUrl, getPayCheckoutUrl } from "utils/SessionUtils"
import { CopyButton } from "../CopyButton/CopyButton"
import "./AffiliateItem.scss"

interface IProps {
  affiliate: Document.Affiliate
  isLoading?: boolean
}

export class AffiliateItem extends React.Component<IProps> {
  public shouldComponentUpdate(nextProps: Readonly<IProps>): boolean {
    const shouldUpdate = JSON.stringify(this.props) !== JSON.stringify(nextProps)

    return shouldUpdate
  }

  public render() {
    const { affiliate, isLoading } = this.props
    return (
      <div className="affiliate-item">
        <a href={getGroupUrl(affiliate?.group?.id)}>
          <div className="AffiliateItem">
            <GroupInfo
              isLoading={isLoading}
              picture={affiliate.group?.picture}
              name={affiliate.group?.name!}
              createdAt={affiliate.group?.createdAt?.toDate().toLocaleDateString()}
              size="medium"
              rightButton={<CopyButton link={`${getPayCheckoutUrl()}/${affiliate.group?.id}?ref=${affiliate.id}`} />}
              isVisible={true}
            />
          </div>
        </a>
      </div>
    )
  }
}
