import { Button, Card, Divider, Stack, Text, styled, useUtility, withUtility } from "@hub-la/design-system"
import CalendarTodayIcon from "@mui/icons-material/CalendarToday"
import EventBusyOutlinedIcon from "@mui/icons-material/EventBusyOutlined"
import EventRepeat from "@mui/icons-material/EventRepeat"
import avatar from "assets/images/placeholder-image.svg"
import moment from "moment"
import "moment/locale/pt-br"
import React from "react"
import { useTranslation } from "react-i18next"
import { SubscriptionStatus } from "../../../../domain/enums/subscription-status"
import { SubscriptionStatusStats } from "./subscription-status-stats"
import { SubscriptionDateBuilder } from "modules/user-subscriptions/usecases/subscription-date-builder"
import { SubscriptionType } from "modules/user-subscriptions/domain/enums/subscription-type"
import { useEnableAutorenew } from "modules/user-subscriptions/presentation/hooks/use-enable-autorenew"

type Props = {
  product: {
    picture?: string
    name: string
    id: string
  }
  subscription: {
    id: string
    status: SubscriptionStatus
    type: SubscriptionType
    createdAt: string
    nextDueAt: string
    canceledAt: string
    inactivatedAt: string
    credits: number
  }
}

/** @todo Use the image optimization from hubla-nx */
const Avatar = styled("img")`
  width: 90px;
  height: auto;
  aspect-ratio: 218 / 142;
  border-radius: 8px;
  object-fit: cover;
  border: 1px solid ${withUtility(({ palette }) => palette("surfaceVariant"))};
`

export const Header: React.FC<Props> = (props) => {
  const { product, subscription } = props
  const { palette } = useUtility()
  const { mutateAsync: enableAutorenew, isLoading } = useEnableAutorenew()
  const { t } = useTranslation()

  const getIcon = (status: SubscriptionStatus) => {
    switch (status) {
      case SubscriptionStatus.ACTIVE:
        return <EventRepeat style={{ color: palette("onSurfaceVariant") }} />
      case SubscriptionStatus.CANCELED:
        return <EventBusyOutlinedIcon style={{ color: palette("onSurfaceVariant") }} />
      default:
        return <CalendarTodayIcon style={{ color: palette("onSurfaceVariant") }} />
    }
  }

  const dateBuilder = new SubscriptionDateBuilder(
    subscription.status,
    subscription.type,
    subscription.nextDueAt,
    moment(new Date()).add(subscription.credits, "day").toISOString(),
    subscription.inactivatedAt,
  )

  return (
    <Card variant="filled" hasHover={false}>
      <Stack direction="row" alignItems="center" gap={2} mb={4}>
        <Avatar src={product.picture ?? avatar} alt={product.name} />
        <Stack direction="column">
          <Stack direction="row" gap={2} alignItems="center">
            <Text variant="h4">{product.name}</Text>
            <SubscriptionStatusStats status={subscription.status} size="tiny" />
          </Stack>
          <Text variant="caption" color="onSurfaceVariant">
            {product.id}
          </Text>
        </Stack>
      </Stack>
      <Divider />
      <Stack direction="row" mt={4}>
        <Stack direction="column" gap={2} width="100%">
          <Text variant="body1">Data de inicio</Text>
          <Stack direction="row" gap={2}>
            <CalendarTodayIcon style={{ color: palette("onSurfaceVariant") }} />
            <Text variant="body1" color="onSurfaceVariant">
              {moment(subscription.createdAt).format("D MMM. YYYY")}
            </Text>
          </Stack>
        </Stack>
        {subscription.credits < 3650 && ( // Shows time for subscriptions that have less than 10 years of credits
          <Stack direction="column" gap={2} width="100%">
            <Text variant="body1">{t(`userSubscriptions.header.date.${dateBuilder.getI18nKey()}`)}</Text>
            <Stack direction="row" gap={2}>
              {getIcon(subscription.status)}
              <Text variant="body1" color="onSurfaceVariant">
                {dateBuilder.execute()}
              </Text>
            </Stack>
          </Stack>
        )}
      </Stack>
      {subscription.status === SubscriptionStatus.CANCELED && (
        <Stack direction="column" mt={4} gap={2}>
          <Button fullWidth onClick={() => enableAutorenew(subscription.id)} loading={isLoading}>
            {t("userSubscriptions.actions.reactivate")}
          </Button>
          <Text variant="body2" color="outline">
            {t("userSubscriptions.actions.reactivateFooter")}
          </Text>
        </Stack>
      )}
    </Card>
  )
}
