import { Document, Events, GroupStepHelpers } from "@chatpay/common"
import { API, Analytics } from "@chatpay/components"
import { DotsLoading, Spacer } from "components"
import { CloseIcon } from "components/CloseIcon"
import i18n from "i18n"
import React, { useState } from "react"
import { Button, Container, Divider, Dropdown, DropdownItemProps, Grid, Loader, Modal } from "semantic-ui-react"
import dropdownIcon from "../../../assets/icons/waitlist/dropdown.svg"
import isDraftIcon from "../../../assets/icons/waitlist/isDraft.svg"
import isNotEnabledIcon from "../../../assets/icons/waitlist/isNotEnabled.svg"
import isSellingIcon from "../../../assets/icons/waitlist/isSelling.svg"
import isWaitlistedIcon from "../../../assets/icons/waitlist/isWaitlisted.svg"
import "./GroupStatusButton.scss"

import { withAccountVerification } from "@hub-la/fe-account-verification"
import { ProductDoesNotHaveGroupIMOrContentDialog } from "modules/product-header/presentation/components/product-does-not-have-group-im-or-content-dialog"
import { useCheckProductHasContentOrOfferHasGroupIMResource } from "modules/product-header/presentation/hooks/use-check-product-has-content-or-offer-has-group-im"
import { GroupPublishButton } from "../GroupPublishButton"
import { GroupPublishButtonOriginPage } from "../GroupPublishButton/GroupPublishButton"
import {
  ButtonContainer,
  DropdownGroupStatusButton,
  MaybeLaterLink,
  ModalContent,
  ModalDescription,
  ModalTitle,
  ModalTitleCenter,
} from "./GroupStatusButtonStyledComponents"

export interface IProps {
  group: Document.Group
  product: Document.Product
  statusChanged?: () => void
  readOnly?: boolean
  borderless?: boolean
  resourceDisabled?: string
}

const DropdownItemWithAccountVerification = withAccountVerification(Dropdown.Item)

const GroupStatusButton: React.FunctionComponent<IProps> = (props) => {
  const groupStepHelpers = new GroupStepHelpers()
  const { group, borderless, readOnly, product, resourceDisabled } = props
  const productHasContentOrOfferHasGroupIMResource = useCheckProductHasContentOrOfferHasGroupIMResource({
    productId: product.id,
    offerResourcesType: group.groupResourcesType,
    isOfferDraft: group.isDraft,
    isOfferWaitlisted: group.isWaitlisted,
    isOfferSelling: group.isSelling,
    isOfferPublished: group.isPublished,
  })

  /** @todo we should refactor all these booleans to a unique state, this current approach is hard to understand and maintain */
  const [
    productHasNoContentOrOfferHasNoGroupIMResourceDialogOpen,
    setProductHasNoContentOrOfferHasNoGroupIMResourceDialogOpen,
  ] = useState<boolean>(false)

  const [isLoading, setIsLoading] = useState<boolean>(false)

  const [isOfferWaitlistWarning, setIsOfferWaitlistWarning] = useState<boolean>(false)
  const [isOfferActivationWarning, setIsOfferActivationWarning] = useState<boolean>(false)
  const [isOfferPublish, setIsOfferPublish] = useState<boolean>(false)
  const [isOfferWaitlisted, setIsOfferWaitlisted] = useState<boolean>(false)
  const [isOfferNotSellingVerificationWarning, setIsOfferNotSellingVerificationWarning] = useState<boolean>(false)
  const [isOfferConfirmInactiveWarning, setIsOfferConfirmInactiveWarning] = useState<boolean>(false)
  const [showModalResourceDisabled, setShowModalResourceDisabled] = useState<boolean>(false)

  const isDesktop = window.matchMedia("(min-width: 992px)").matches

  const productStatusTracker = (
    event: string,
    product: Document.Product | null | undefined,
    group: Document.Group,
    status: Document.GroupStepStatus,
  ) => {
    Analytics.track(event, {
      productId: product?.id,
      userId: product?.owner?.id,
      productType: product?.type,
      offerID: group.id,
      statusType: status,
      statusOrigin: groupStepHelpers.getGroupStepStatus(group),
    })
  }

  const handleChangeOfferStatus = async (status: Document.GroupStepStatus) => {
    setIsLoading(true)
    await new API.Group().changeStatus({
      groupId: group.id,
      status: status,
    })
    setIsLoading(false)
    props.statusChanged && props.statusChanged()
  }

  const handleExceptions = async (status: Document.GroupStepStatus) => {
    // check product has at least a content or group before being enabled just when enabling product
    if (!productHasContentOrOfferHasGroupIMResource && Document.GroupStepStatus.isSelling === status) {
      setIsLoading(false)
      setProductHasNoContentOrOfferHasNoGroupIMResourceDialogOpen(true)
      return
    }

    if (status === Document.GroupStepStatus.isSelling || status === Document.GroupStepStatus.isWaitlisted) {
      if (status === Document.GroupStepStatus.isSelling) {
        setIsOfferPublish(true)
      }
      if (status === Document.GroupStepStatus.isWaitlisted) {
        setIsOfferWaitlisted(true)
      }
      setIsOfferActivationWarning(true)
      setIsLoading(false)
      return
    }

    handleChangeOfferStatus(status)

    if (group.unavailable.isOn === true) {
      return Document.GroupStepStatus.isUnavailable
    }
  }

  const statusToDropdownItem = (statusArray: Document.GroupStepStatus[]): DropdownItemProps[] => {
    return statusArray.map((status, index) => {
      /**
       * @description If the user is trying to activate the product, we need to check if the account is already verified.
       * This is done in the `withAccountVerification` HOC, wich checks for the feature flag as well.
       */
      const DropdownItem =
        status === Document.GroupStepStatus.isSelling ? DropdownItemWithAccountVerification : Dropdown.Item
      return (
        <DropdownItem
          key={index}
          selected={false}
          text={i18n.t(`Dashboard.Waitlist.GroupStatus.${status}.action`)}
          description={i18n.t(`Dashboard.Waitlist.GroupStatus.${status}.description`)}
          icon={<img src={getIconByStatus(status)} alt={"group icon"} className="menu-icon" />}
          value={status}
          onClick={async () => {
            if (resourceDisabled && status === Document.GroupStepStatus.isSelling) {
              setShowModalResourceDisabled(true)
            } else {
              productStatusTracker(Events.PRODUCT.STATUS_CLICKED, product, group, status)
              setIsLoading(true)
              handleExceptions(status)
            }
          }}
        />
      )
    })
  }

  const getOptionsByGroupStatus = (status: Document.GroupStepStatus): DropdownItemProps[] => {
    switch (status) {
      case Document.GroupStepStatus.isDraft: {
        return statusToDropdownItem([Document.GroupStepStatus.isWaitlisted, Document.GroupStepStatus.isSelling])
      }
      case Document.GroupStepStatus.isSelling: {
        return statusToDropdownItem([Document.GroupStepStatus.isWaitlisted, Document.GroupStepStatus.isNotSelling])
      }
      case Document.GroupStepStatus.isWaitlisted: {
        return statusToDropdownItem([Document.GroupStepStatus.isSelling, Document.GroupStepStatus.isNotEnabled])
      }
      case Document.GroupStepStatus.isNotEnabled: {
        return statusToDropdownItem([Document.GroupStepStatus.isWaitlisted, Document.GroupStepStatus.isSelling])
      }
      case Document.GroupStepStatus.isUnavailable: {
        return statusToDropdownItem([])
      }
      default: {
        return statusToDropdownItem([Document.GroupStepStatus.isWaitlisted, Document.GroupStepStatus.isSelling])
      }
    }
  }

  if (readOnly) {
    const status = groupStepHelpers.getGroupStepStatus(group)
    const normalizeClassName = (str) => str.replace(/[A-Z]/g, (letter) => `-${letter.toLowerCase()}`)

    return (
      <span className={`group-status-label ${normalizeClassName(status)}`}>
        {i18n.t(`Dashboard.Waitlist.GroupStatus.${status}.current`)}
      </span>
    )
  }

  return (
    <>
      <ProductDoesNotHaveGroupIMOrContentDialog
        open={productHasNoContentOrOfferHasNoGroupIMResourceDialogOpen}
        onClose={() => setProductHasNoContentOrOfferHasNoGroupIMResourceDialogOpen(false)}
      />

      {isOfferActivationWarning && (
        <GroupPublishButton
          product={product}
          originPage={GroupPublishButtonOriginPage.productStatusButton}
          group={group}
          statusChanged={() => {
            setIsOfferActivationWarning(false)
            setIsOfferPublish(false)
            setIsOfferWaitlisted(false)
            props.statusChanged && props.statusChanged()
          }}
          closed={() => {
            setIsOfferActivationWarning(false)
            setIsOfferPublish(false)
            setIsOfferWaitlisted(false)
            props.statusChanged && props.statusChanged()
          }}
          justModal={isOfferActivationWarning}
          isPublish={isOfferPublish}
          isWaitlisted={isOfferWaitlisted}
        />
      )}

      <DropdownGroupStatusButton
        className={`${isLoading && "loading"} dropdown-waitlist ${borderless ? "" : "border"} group-status-button ${
          readOnly ? "readOnly" : ""
        }`}
        data-testid="dropdown-status"
        disabled={isLoading}
        size="small"
        text={i18n.t(`Dashboard.Waitlist.GroupStatus.${groupStepHelpers.getGroupStepStatus(group)}.current`)}
        icon={
          <>
            {isLoading && (
              <Loader className="main-icon" size="mini" style={{ height: "14px !important" }}>
                Loading
              </Loader>
            )}
            <img
              src={getIconByStatus(groupStepHelpers.getGroupStepStatus(group))}
              alt={"group icon"}
              className="main-icon status-icon"
            />
            <img src={dropdownIcon} alt={"group icon"} className="dropdown-icon" />
          </>
        }
        loading={isLoading}
        options={getOptionsByGroupStatus(groupStepHelpers.getGroupStepStatus(group))}
        item={true}
      />

      <Modal open={isOfferWaitlistWarning}>
        <ModalContent>
          <div style={{ display: "flex", justifyContent: "flex-end" }} title={i18n.t("close")}>
            <CloseIcon onClick={() => setIsOfferWaitlistWarning(false)} />
          </div>
          <Spacer size={isDesktop ? "big" : "small"} />
          <Grid.Row>
            <Grid.Column computer={12} mobile={16}>
              <ModalTitle>{i18n.t("GroupStatusButton.offerWaitlistWarning.modal.title")}</ModalTitle>
              <Spacer size="small" />
              <ModalDescription
                dangerouslySetInnerHTML={{ __html: i18n.t("GroupStatusButton.offerWaitlistWarning.modal.description") }}
              />
              <Spacer size={isDesktop ? "big" : "small"} />
            </Grid.Column>
          </Grid.Row>
          <Divider />
          <Container>
            <Grid>
              <Grid.Row>
                <Grid.Column computer={12} mobile={16}>
                  <ButtonContainer>
                    <MaybeLaterLink onClick={() => setIsOfferWaitlistWarning(false)}>
                      {i18n.t("GroupStatusButton.offerWaitlistWarning.modal.buttons.maybeLater")}
                    </MaybeLaterLink>
                    <Button
                      content={i18n.t("GroupStatusButton.offerWaitlistWarning.modal.buttons.createWaitlist")}
                      size="small"
                      color="green"
                      onClick={async () => {
                        await handleChangeOfferStatus(Document.GroupStepStatus.isWaitlisted)
                        setIsOfferWaitlistWarning(false)
                      }}
                      loading={isLoading}
                    />
                  </ButtonContainer>
                  <Spacer />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Container>
        </ModalContent>
      </Modal>

      <Modal open={isOfferConfirmInactiveWarning} style={{ textAlign: "center" }}>
        <ModalContent>
          <Spacer size="big" />
          <Grid.Row>
            <Grid.Column computer={12} mobile={16}>
              <ModalTitleCenter>
                {i18n.t(`GroupStatusButton.offerNotSellingWarning.modal.confirm.title`)}
              </ModalTitleCenter>
              <Spacer size="small" />
              <ModalDescription>
                {i18n.t(`GroupStatusButton.offerNotSellingWarning.modal.confirm.description`)}
              </ModalDescription>
            </Grid.Column>
          </Grid.Row>
          <Spacer size="big" />
          <Button
            content="Fechar"
            size="small"
            color="green"
            onClick={() => setIsOfferConfirmInactiveWarning(false)}
            loading={isLoading}
          />
        </ModalContent>
      </Modal>

      <Modal
        open={isOfferNotSellingVerificationWarning}
        onMount={async () => {
          await handleChangeOfferStatus(Document.GroupStepStatus.isNotSelling)
          setIsOfferNotSellingVerificationWarning(false)
          setIsOfferConfirmInactiveWarning(true)
        }}
        style={{ textAlign: "center" }}
      >
        <ModalContent>
          <Spacer size="big" />
          <Grid.Row>
            <Grid.Column computer={12} mobile={16}>
              <DotsLoading />
              <ModalTitleCenter>{i18n.t("GroupStatusButton.offerNotSellingWarning.modal.title")}</ModalTitleCenter>
              <Spacer size="small" />
              <ModalDescription>
                {i18n.t(`GroupStatusButton.offerNotSellingWarning.modal.description`)}
              </ModalDescription>
            </Grid.Column>
          </Grid.Row>
          <Spacer size="big" />
        </ModalContent>
      </Modal>

      <Modal open={showModalResourceDisabled} style={{ textAlign: "center" }}>
        <ModalContent>
          <Spacer size="big" />
          <Grid.Row>
            <Grid.Column computer={12} mobile={16}>
              <ModalTitleCenter>
                {i18n.t("GroupStatusButton.resourceDisabled.modal.title", { resource_name: resourceDisabled })}
              </ModalTitleCenter>
              <Spacer size="small" />
              <ModalDescription
                dangerouslySetInnerHTML={{
                  __html: i18n.t("GroupStatusButton.resourceDisabled.modal.description", {
                    resource_name: resourceDisabled,
                  }),
                }}
              ></ModalDescription>
            </Grid.Column>
          </Grid.Row>
          <Spacer size="big" />
          <Button content="Fechar" size="small" color="green" onClick={() => setShowModalResourceDisabled(false)} />
        </ModalContent>
      </Modal>
    </>
  )
}

export const getIconByStatus = (status: Document.GroupStepStatus) => {
  const icons = {
    [Document.GroupStepStatus.isSelling]: isSellingIcon,
    [Document.GroupStepStatus.isWaitlisted]: isWaitlistedIcon,
    [Document.GroupStepStatus.isDraft]: isDraftIcon,
    [Document.GroupStepStatus.isNotSelling]: isNotEnabledIcon,
    [Document.GroupStepStatus.isNotEnabled]: isNotEnabledIcon,
  }
  return icons[status]
}

export default GroupStatusButton
