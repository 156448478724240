import { CheckLogReducer } from "pages/CheckLog/CheckLogReducer"
import { RefundReducer } from "pages/User/Refund/RefundReducer"
import { WhitelistReducer } from "pages/User/Whitelist/WhitelistReducer"
import { applyMiddleware, combineReducers, createStore, Store as ReduxStore } from "redux"
import { composeWithDevTools } from "redux-devtools-extension"
import thunk from "redux-thunk"
import { AuthReducer } from "../auth/AuthReducer"
import { GetAffiliateReducer } from "../components/Dialogs/GetAffiliate/GetAffiliateReducer"
import { ActivitiesReducer } from "../components/Pagination/Activities/ActivitiesReducer"
import { AffiliatesReducer } from "../components/Pagination/Affiliates/AffiliatesReducer"
import { RefundsReducer } from "../components/Pagination/Refunds/RefundsReducer"
import { AffiliateReducer } from "../pages/User/Affiliate/AffiliateReducer"
import { GroupsOrderReducer } from "../pages/User/GroupsOrder/GroupsOrderReducer"
import { Groups, User } from "../store"
import { IAppState } from "./AppState"

// Create the root reducer
const rootReducer = combineReducers<IAppState>({
  authState: AuthReducer,
  GroupsOrderState: GroupsOrderReducer,
  AffiliatesState: AffiliatesReducer,
  RefundsState: RefundsReducer,
  ActivitiesState: ActivitiesReducer,
  getAffiliateState: GetAffiliateReducer,
  AffiliateState: AffiliateReducer,
  WhitelistState: WhitelistReducer,
  RefundState: RefundReducer,
  CheckLogState: CheckLogReducer,
  GroupsState: Groups.Reducer,
  UserState: User.Reducer,
})

// Create a configure store function of type `IAppState`
export default function configureStore(): ReduxStore<IAppState, any> {
  const middlewares = [thunk]
  return createStore(rootReducer, undefined, composeWithDevTools(applyMiddleware(...middlewares)))
}
