import React, { useState } from "react"
import { Form } from "semantic-ui-react"
import * as Fields from "components/Fields"
import { useTranslation } from "react-i18next"
import { IFileAsset } from "components/Interfaces/IFileAsset"
import { Editor as TinyMCEEditor } from "tinymce"

export interface IForm {
  welcomeText: string
  files: IFileAsset[]
}

export interface IProps {
  loading: boolean
  value?: Partial<IForm>
  onChange: (data: Partial<IForm>, editor?: TinyMCEEditor) => any
}

const Welcome: React.FunctionComponent<IProps> = (props) => {
  const { loading, value, onChange } = props
  const [form, setForm] = useState<Partial<IForm>>({
    welcomeText: value?.welcomeText,
    files: value?.files,
  })
  const { t } = useTranslation()

  const onTextUpdate = (data: string, editor: TinyMCEEditor) => {
    const newData = {
      ...form,
      welcomeText: data,
    }
    setForm(newData)
    onChange(newData, editor)
  }

  const onFileUpdate = (files?: IFileAsset[]) => {
    const newData = {
      ...form,
      files,
    }
    setForm(newData)
    onChange(newData)
  }

  return (
    <Form>
      <Fields.InputLabel title={t("GroupForm.welcome.label")}>
        <Fields.Editor
          disabled={loading}
          init={{
            min_height: 220,
            content_style: "body { font-size: 14px; }",
            fontsize_formats: "14px",
            toolbar: ["undo redo | bold italic bullist numlist link removeformat"],
            toolbar1: undefined,
            toolbar2: undefined,
            contextmenu: "",
            plugins: "link autolink lists paste code",
            link_title: false,
            default_link_target: "_blank",
            paste_data_images: false,
            paste_as_text: true,
            paste_merge_formats: false,
            smart_paste: false,
            automatic_uploads: false,
            language: "pt_BR",
            end_container_on_empty_block: false,
            browser_spellcheck: true,
            convert_urls: false,
          }}
          value={form?.welcomeText ?? ""}
          placeholder={t("GroupForm.welcome.placeholder")}
          onEditorChange={(data, editor) => onTextUpdate(data, editor)}
        />
        <Fields.InputFiles disabled={loading} files={form?.files} onChange={(files) => onFileUpdate(files)} />
      </Fields.InputLabel>
    </Form>
  )
}

export { Welcome as Form }
