import { Card, Divider, Stack, Text, useUtility } from "@hub-la/design-system"
import CalendarTodayIcon from "@mui/icons-material/CalendarToday"
import { formatCurrency } from "modules/user-invoices/domain/vos/format-currency"
import moment from "moment"
import React from "react"
import { InvoiceStatus } from "../../../../domain/enums/invoice-status"
import { InvoiceStatusStats } from "../../../components/invoice-status-stats"
import { SmartInstallmentRef } from "modules/user-invoices/domain/dtos/smart-installment-ref"
import { SmartInstallmentChip } from "modules/user-invoices/presentation/components/smart-installment-chip"

type Props = {
  invoice: {
    id: string
    status: InvoiceStatus
    createdAt: string
    dueDate: string
    paidAt: string
    total: number
    installment: number
    installments: number
    smartInstallmentRef?: SmartInstallmentRef
  }
}

export const Header: React.FC<Props> = (props) => {
  const { invoice } = props
  const { palette } = useUtility()
  return (
    <Card variant="outlined" hasHover={false}>
      <Stack direction="row" alignItems="center" gap={2} mb={4}>
        <Stack direction="column" gap={0.5}>
          <Stack direction="row" gap={2} alignItems="center">
            <Text variant="h2">{formatCurrency(invoice.total / 100)}</Text>
            <InvoiceStatusStats status={invoice.status} size="tiny" />
            {invoice.smartInstallmentRef && (
              <SmartInstallmentChip
                status={invoice.smartInstallmentRef.status}
                size="tiny"
                installment={invoice.installment}
                installments={invoice.installments}
              />
            )}
          </Stack>
          <Text variant="caption" color="outline">
            ID Fatura: {invoice.id}
          </Text>
        </Stack>
      </Stack>
      <Divider />
      <Stack direction="column" gap={4} mt={4}>
        <Stack direction="column" width="100%">
          <Text variant="body1">Data de criação</Text>
          <Stack direction="row" gap={2}>
            <CalendarTodayIcon style={{ color: palette("outline") }} />
            <Text variant="body1" color="outline">
              {moment(invoice.createdAt).format("D MMM. YYYY HH:mm")}
            </Text>
          </Stack>
        </Stack>
        {invoice.status === InvoiceStatus.PAID && (
          <Stack direction="column" width="100%">
            <Text variant="body1">Data de pagamento</Text>
            <Stack direction="row" gap={2}>
              <CalendarTodayIcon style={{ color: palette("outline") }} />
              <Text variant="body1" color="outline">
                {moment(invoice.paidAt).format("D MMM. YYYY HH:mm")}
              </Text>
            </Stack>
          </Stack>
        )}
      </Stack>
    </Card>
  )
}
