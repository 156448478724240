import { HttpClient } from "@hub-la/fe-core-http-client"
import isNil from "lodash/isNil"
import { useQuery, useQueryClient } from "react-query"
import { GetUpgradeState } from "../../usecases/get-upgrade-state"
import { useContainer } from "@hub-la/fe-container"
import { QueryKey } from "modules/user-subscriptions/domain/enums/query-key"
import { UpgradeStatus } from "modules/user-subscriptions/domain/enums/upgrade-status"

export const useGetUpgradeState = (subscriptionId?: string, enabled?: boolean) => {
  const container = useContainer()
  const queryClient = useQueryClient()

  return useQuery(
    [QueryKey.getUpgradeState, subscriptionId],
    () => new GetUpgradeState(container.get(HttpClient)).execute(subscriptionId),
    {
      refetchInterval: (data, query) => {
        if (isNil(data)) {
          return false
        }

        const canRetry = data.status === UpgradeStatus.PENDING

        if (query.state.error) {
          return false
        }

        return canRetry ? 2000 : false
      },
      retryOnMount: true,
      refetchOnWindowFocus: "always",
      staleTime: Infinity,
      cacheTime: 0,
      enabled: !!subscriptionId && enabled,
      onSuccess: (data) => {
        if (data.status === UpgradeStatus.SUCCESS) {
          queryClient.invalidateQueries([QueryKey.initUpgradePlan, subscriptionId])
          queryClient.invalidateQueries([QueryKey.getSubscription, subscriptionId])
          queryClient.invalidateQueries([QueryKey.getSubscriptionValue, subscriptionId])
        }
      },
    },
  )
}
