import React from "react"
import { Stack, Stats, StatsVariant } from "@hub-la/design-system"
import StreamIcon from "@mui/icons-material/Stream"
import { SmartInstallmentStatus } from "modules/user-invoices/domain/enums/smart-installment-status"

type SmartInstallmentChipProps = {
  status: SmartInstallmentStatus
  installment: number
  installments: number
  size: "large" | "small" | "tiny"
}

export const SmartInstallmentChip = ({ status, size, installment, installments }: SmartInstallmentChipProps) => {
  const getVariant = () => {
    switch (status) {
      case SmartInstallmentStatus.COMPLETED:
      case SmartInstallmentStatus.ON_SCHEDULE:
        return StatsVariant.positive
      case SmartInstallmentStatus.OFF_SCHEDULE:
        return StatsVariant.negative
      case SmartInstallmentStatus.CANCELED:
        return StatsVariant.neutral

      default:
        return StatsVariant.informative
    }
  }
  return (
    <Stats variant={getVariant()} size={size}>
      <Stack direction="row" gap={1}>
        <StreamIcon style={{ fontSize: "16px" }} />
        {installment}/{installments}
      </Stack>
    </Stats>
  )
}
