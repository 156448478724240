import { Interface } from "@chatpay/common"
import * as Fields from "components/Fields"
import React, { useEffect, useState } from "react"
import { Form, InputOnChangeData } from "semantic-ui-react"
import { Facebook } from "./Facebook"

export interface IForm {
  trackers: Interface.Trackers.ITrackers
}

export interface IProps {
  value?: Partial<IForm>
  saved?: boolean
  onChange?: (data: Partial<IForm>) => any
}

const Trackers: React.FunctionComponent<IProps> = (props) => {
  const { value, onChange, saved } = props

  const [form, setForm] = useState<Partial<IForm>>()

  useEffect(() => {
    if (JSON.stringify(value) !== JSON.stringify(form)) {
      setForm(value)
    }
  }, [value, form])

  const updateFacebookPixel = (data?: Interface.Trackers.Facebook | null) => {
    const newData = {
      trackers: {
        ...form?.trackers,
        facebook: data,
      },
    }
    if (onChange) {
      onChange(newData)
    }
    setForm(newData)
  }

  const updateTagManagerPixel = (event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
    const result = data.value
    const newData = {
      trackers: {
        ...form?.trackers,
        tagManager: result?.trim() || null,
      },
    }
    if (onChange) {
      onChange(newData)
    }
    setForm(newData)
    event.currentTarget.value = result
  }

  const updateGA4Pixel = (event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
    const result = data.value
    const newData = {
      trackers: {
        ...form?.trackers,
        googleAnalytics: result?.trim() || null,
      },
    }
    if (onChange) {
      onChange(newData)
    }
    setForm(newData)
    event.currentTarget.value = result
  }

  return (
    <Form>
      <Facebook value={form?.trackers?.facebook} saved={saved} onChange={(data) => updateFacebookPixel(data)} />
      <Fields.InputLabel title="Google Tag Manager">
        <Form.Input
          value={form?.trackers?.tagManager ?? ""}
          placeholder="Ex: GTM-123456"
          onChange={(event, data) => updateTagManagerPixel(event, data)}
          maxLength={16}
        />
      </Fields.InputLabel>
      <Fields.InputLabel title="Google Analytics 4" style={{ marginTop: "2em" }}>
        <Form.Input
          value={form?.trackers?.googleAnalytics ?? ""}
          placeholder="Ex: G-1FRX00YCKR"
          onChange={(event, data) => updateGA4Pixel(event, data)}
          maxLength={160}
        />
      </Fields.InputLabel>
    </Form>
  )
}

export { Trackers as Form }
