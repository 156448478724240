import { Reducer } from "redux"
import { AffiliateAction, AffiliateActionTypes } from "./AffiliateActions"
import { IAffiliateState } from "./AffiliateState"

const initialAffiliateState: IAffiliateState = {
  isLoaded: false,
  error: null,
  myAffiliates: [],
}

export const AffiliateReducer: Reducer<IAffiliateState, AffiliateAction> = (
  state: IAffiliateState = initialAffiliateState,
  action: AffiliateAction,
) => {
  switch (action.type) {
    case AffiliateActionTypes.LOAD:
      return {
        ...state,
      }
    case AffiliateActionTypes.LOAD_SUCCESS:
      return {
        ...state,
        isLoaded: true,
        myAffiliates: action.myAffiliates,
      }
    case AffiliateActionTypes.LOAD_FAILURE:
      return {
        ...state,
      }
    default:
      return state
  }
}
