import { Button, Card, Divider, Stack, Text, styled } from "@hub-la/design-system"
import { formatCurrency } from "modules/user-invoices/domain/vos/format-currency"
import moment from "moment"
import React from "react"
import { useTranslation } from "react-i18next"
import { InvoiceStatus } from "../../../../domain/enums/invoice-status"
import { InvoiceStatusStats } from "../../../components/invoice-status-stats"
import { routes } from "@chatpay/common"
import active_order from "../../../assets/active_order.svg"
import inactive_order from "../../../assets/inactive_order.svg"
import returned_order from "../../../assets/returned_order.svg"
import { SmartInstallmentRef } from "modules/user-invoices/domain/dtos/smart-installment-ref"
import { SmartInstallmentChip } from "modules/user-invoices/presentation/components/smart-installment-chip"
import { getInvoicePaymentUrl } from "utils/SessionUtils"

type Props = {
  invoice: {
    id: string
    status: InvoiceStatus
    createdAt: string
    dueDate: string
    paidAt: string
    total: number
    installment: number
    installments: number
    smartInstallmentRef?: SmartInstallmentRef
  }
  product: {
    name: string
  }
  payer: {
    fullName: string
  }
  seller: {
    fullName: string
  }
}

const StatusIcon = styled("img")`
  width: 56px;
  height: auto;
  object-fit: contain;
  object-position: center;
`

const getIcon = (invoiceStatus?: InvoiceStatus) => {
  switch (invoiceStatus) {
    case InvoiceStatus.PAID:
    case InvoiceStatus.DISPUTED:
      return <StatusIcon src={active_order} aria-label="active_order" />
    case InvoiceStatus.UNPAID:
    case InvoiceStatus.EXPIRED:
    case InvoiceStatus.DRAFT:
    case InvoiceStatus.CANCELED:
      return <StatusIcon src={inactive_order} aria-label="inactive_order" />
    case InvoiceStatus.RETURNED:
    case InvoiceStatus.CHARGEBACK:
      return <StatusIcon src={returned_order} aria-label="returned_order" />
    default:
      return ""
  }
}

export const Header: React.FC<Props> = (props) => {
  const { invoice, product, payer, seller } = props
  const { t } = useTranslation()

  const format = (date?: string) => moment(date).format("D MMM. YYYY HH:mm")
  const getDate = (): string => {
    moment.locale("pt-br")
    if ([InvoiceStatus.EXPIRED, InvoiceStatus.UNPAID, InvoiceStatus.DRAFT].includes(invoice.status)) {
      return format(invoice.dueDate)
    } else if ([InvoiceStatus.CANCELED, InvoiceStatus.RETURNED, InvoiceStatus.CHARGEBACK].includes(invoice.status)) {
      return ""
    } else {
      return format(invoice.paidAt)
    }
  }

  const getTotal = () => {
    if (invoice.installments > 1) {
      return `${invoice.installments}x de ${formatCurrency(invoice.total / invoice.installments / 100)}`
    }
    return formatCurrency(invoice.total / 100)
  }

  const canPayInvoice = [InvoiceStatus.UNPAID, InvoiceStatus.EXPIRED].includes(invoice.status)

  return (
    <Card variant="filled" hasHover={false}>
      <Stack direction="column" gap={6} mb={6}>
        <Stack direction="row" gap={2} alignItems="center">
          <InvoiceStatusStats status={invoice.status} size="small" />
          {invoice.smartInstallmentRef && (
            <SmartInstallmentChip
              status={invoice.smartInstallmentRef.status}
              size="small"
              installment={invoice.installment}
              installments={invoice.installments}
            />
          )}
        </Stack>
        <Stack direction="row" justifyContent="space-between" alignItems="start">
          <Stack direction="column" gap={0.5}>
            <Text variant="body2" color="onSurfaceVariant">
              {product.name}
            </Text>
            <Text variant="h3">{getTotal()}</Text>
            <Text variant="body2" color="onSurfaceVariant">
              {t(`userInvoices.header.date.${invoice.status}`)} {getDate()}
            </Text>
          </Stack>
          {getIcon(invoice.status)}
        </Stack>
      </Stack>

      <Divider />

      <Stack direction="column" gap={4} mt={4}>
        <Stack direction="column">
          <Text variant="body1">De</Text>
          <Text variant="body1" color="onSurfaceVariant">
            {seller.fullName}
          </Text>
        </Stack>
        <Stack direction="column">
          <Text variant="body1">Para</Text>
          <Text variant="body1" color="onSurfaceVariant">
            {payer.fullName}
          </Text>
        </Stack>
      </Stack>
      <Stack direction="column" mt={4} gap={2}>
        {canPayInvoice && (
          <Button fullWidth onClick={() => window.open(`${getInvoicePaymentUrl()}/${invoice?.id}`, "_blank")}>
            {t("userInvoices.actions.pay")}
          </Button>
        )}
        <Button
          variant="text"
          hierarchy="secondary"
          fullWidth
          onClick={() => window.open(`${routes.USER_DOWNLOAD_INVOICE.LINK}/${invoice?.id}/pdf`, "_blank")}
        >
          {t("userInvoices.actions.download")}
        </Button>
      </Stack>
    </Card>
  )
}
