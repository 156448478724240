import { Box, Card, Divider, Grid, Stack, Text, useUtility } from "@hub-la/design-system"
import CalendarTodayIcon from "@mui/icons-material/CalendarToday"
import { Skeleton } from "@mui/material"
import React from "react"

const Loading: React.FC = () => {
  const { palette } = useUtility()

  return (
    <Box py={12} px={[6, 6, 6, 0]} maxWidth={946} width="100%" margin="auto">
      <Stack direction="row" justifyContent="space-between" alignItems="center" mb={12}>
        <Text variant="h2" color="outline">
          Detalhes da fatura
        </Text>
        <img style={{ width: "136px" }} src={require("../../assets/hubla-logo.png")} alt={"hubla logo avatar"} />
      </Stack>

      <Grid container spacing={4}>
        <Grid item xs={12} md={12}>
          <Card variant="outlined" hasHover={false}>
            <Stack direction="row" alignItems="center" gap={2} mb={4}>
              <Stack direction="column" gap={0.5}>
                <Stack direction="row" gap={2} alignItems="center">
                  <Text variant="h4">
                    <Skeleton width={130} />
                  </Text>
                  <Skeleton width={59} height={32} />
                </Stack>
                <Text variant="caption" color="onSurfaceVariant">
                  <Skeleton width={130} />
                </Text>
              </Stack>
            </Stack>
            <Divider />
            <Stack direction="column" mt={4} gap={4}>
              <Stack direction="column" width="100%">
                <Text variant="body1">Data de criação</Text>
                <Stack direction="row" gap={2}>
                  <CalendarTodayIcon style={{ color: palette("onSurfaceVariant") }} />
                  <Text variant="body1" color="onSurfaceVariant">
                    <Skeleton width={110} />
                  </Text>
                </Stack>
              </Stack>
              <Stack direction="column" width="100%">
                <Text variant="body1">Data de pagamento</Text>
                <Stack direction="row" gap={2}>
                  <CalendarTodayIcon style={{ color: palette("onSurfaceVariant") }} />
                  <Text variant="body1" color="onSurfaceVariant">
                    <Skeleton width={110} />
                  </Text>
                </Stack>
              </Stack>
            </Stack>
          </Card>
        </Grid>

        <Grid item xs={12} md={6}>
          <Card variant="outlined" hasHover={false} fullHeight>
            <Stack direction="column" width="100%">
              <Text variant="body1">Cobrança</Text>
              <Text variant="body1" color="onSurfaceVariant">
                <Skeleton width={110} />
              </Text>
              <Text variant="body1" color="onSurfaceVariant">
                <Skeleton width={110} />
              </Text>
              <Text variant="body1" color="onSurfaceVariant">
                <Skeleton width={110} />
              </Text>
            </Stack>
          </Card>
        </Grid>

        <Grid item xs={12} md={6}>
          <Card variant="outlined" hasHover={false} fullHeight>
            <Stack direction="column" width="100%" gap={2}>
              <Text variant="body1">Faturamento</Text>
              <Text variant="body1" color="onSurfaceVariant">
                <Skeleton width={110} />
              </Text>
              <Text variant="body1" color="onSurfaceVariant">
                <Skeleton width={110} />
              </Text>
            </Stack>
          </Card>
        </Grid>

        <Grid item xs={12} md={12}>
          <Card variant="outlined" hasHover={false} fullHeight>
            <Stack direction="column" justifyContent="space-between" height="100%">
              <Stack direction="column" width="100%">
                <Text variant="body1">Método de pagamento</Text>
                <Text variant="body1" color="onSurfaceVariant">
                  <Skeleton width={110} />
                </Text>
              </Stack>
            </Stack>
          </Card>
        </Grid>

        <Grid item xs={12} marginBottom={6}>
          <Stack height="100%" width="100%">
            <Card hasHover={false} variant="outlined" fullHeight>
              <Stack flexDirection="column" justifyContent="space-between" gap={5}>
                <Stack direction="column" width="100%" gap={2}>
                  <Text variant="body1" fontWeight="bold">
                    Detalhes da fatura
                  </Text>
                </Stack>
                <Stack direction="column" width="100%" gap={2}>
                  <Skeleton width={120} height={24} />
                  <Divider />
                  <Skeleton width={76} height={24} />
                  <Divider />
                  <Skeleton width={80} height={24} />
                  <Divider />
                  <Skeleton width={93} height={24} />
                </Stack>
              </Stack>
            </Card>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  )
}

export default Loading
