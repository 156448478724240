import { Document, Interface } from "@chatpay/common"
import { DB } from "../Service"
import API from "./API"

export enum FetchWho {
  user = "to",
  owner = "from",
}

class Refund extends API implements Interface.Refund.Function.ITemplate {
  private db = new DB(Document.Refund.Document)

  public async request(data: Interface.Refund.Function.IRequest): Promise<Document.Data<Document.Refund.Document>> {
    return (await this.call(Interface.Refund.Function.Name.request, data))?.data
  }
  public async accept(data: Interface.Refund.Function.IAccept): Promise<Document.Data<Document.Refund.Document>> {
    return (await this.call(Interface.Refund.Function.Name.accept, data))?.data
  }
  public async reject(data: Interface.Refund.Function.IReject): Promise<Document.Data<Document.Refund.Document>> {
    return (await this.call(Interface.Refund.Function.Name.reject, data))?.data
  }
  public async cancel(data: Interface.Refund.Function.ICancel): Promise<Document.Data<Document.Refund.Document>> {
    return (await this.call(Interface.Refund.Function.Name.cancel, data))?.data
  }
  public async reactivate(
    data: Interface.Refund.Function.IReactivate,
  ): Promise<Document.Data<Document.Refund.Document>> {
    return (await this.call(Interface.Refund.Function.Name.reactivate, data))?.data
  }

  private async call(func: Interface.Refund.Function.Name, params?: Interface.Refund.Function.Params) {
    return await this.callFunction(`refund/${func}`, params)
  }

  // QUERIES
  public async fetch(who: FetchWho): Promise<Document.Refund.Document[]> {
    const currentUserId = API.currentRoleplayUserId ?? API.currentUser?.id
    if (!currentUserId) {
      return Promise.resolve([])
    }

    try {
      return await this.db.get({
        where: [
          { field: `${who}.id`, op: "==", value: currentUserId },
          { field: "isDeleted", op: "==", value: false },
        ],
      })
    } catch (e) {
      console.error(e)
      return Promise.resolve([])
    }
  }

  public async retrieve(id: string): Promise<Document.Refund.Document | null> {
    try {
      return await this.db.getById(id)
    } catch (e) {
      console.error(e)
      return null
    }
  }
}

export default Refund
