import React from "react"
import { Pagination, Snackbar, Stack, Table, TableCell, TableRow, Text } from "@hub-la/design-system"
import CreditCardIcon from "@mui/icons-material/CreditCard"
import PixIcon from "@mui/icons-material/Pix"
import isEmpty from "lodash/isEmpty"
import times from "lodash/times"
import moment from "moment"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import { Invoice } from "../../../../domain/dtos/invoice"
import { OrderDirection } from "../../../../domain/enums/order-direction"
import { PaymentMethod } from "../../../../domain/enums/payment-method"
import { formatCurrency } from "../../../../domain/vos/format-currency"
import { InvoiceStatusStats } from "../../../components/invoice-status-stats"
import { SmartInstallmentChip } from "../../../components/smart-installment-chip"
import { useCheckHaveChanged } from "../../../hooks/use-check-have-changed"
import { useGetInvoices } from "../../../hooks/use-get-invoices"
import { InvoiceTableLoading } from "./invoice-table-loading"
import { routes } from "@chatpay/common"

export const InvoiceTable = ({ offers, filters }) => {
  const [page, setPage] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(25)
  const [orderDirection, setOrderDirection] = useState<OrderDirection>(OrderDirection.DESC)
  const { t } = useTranslation()
  const {
    data = { items: [], total: 0, page, pageSize: pageSize, lastPage: 1 },
    isFetching,
    refetch,
    error,
  } = useGetInvoices({
    offerIds: filters.offerIds,
    offers,
    page: page,
    pageSize: pageSize,
    orderDirection: orderDirection,
    orderBy: "createdAt",
  })
  const history = useHistory()

  const isRowsEmpty = isEmpty(data?.items)
  const canRenderEmptyState = !isFetching && isRowsEmpty

  const getHeadCells = () => {
    return [
      { key: "total", label: t("userInvoices.table.total"), sortable: false, width: 90 },
      { key: "status", label: t("userInvoices.table.status"), sortable: false, width: 70 },
      { key: "product", label: t("userInvoices.table.product"), sortable: false, width: 110 },
      { key: "type", label: t("userInvoices.table.type"), sortable: false, width: 70 },
      { key: "createdAt", label: t("userInvoices.table.createdAt"), sortable: false, width: 110 },
    ]
  }

  const getIcon = (paymentMethod: PaymentMethod) => {
    switch (paymentMethod) {
      case PaymentMethod.PIX:
        return <PixIcon />
      case PaymentMethod.CREDIT_CARD:
        return <CreditCardIcon />
      default:
        // eslint-disable-next-line react/jsx-no-useless-fragment
        return <></>
    }
  }

  const redirectToInvoiceDetail = (id: string) => history.push(routes.USER_INVOICES.LINK + "/" + id)

  const canRefetch = useCheckHaveChanged({ page, pageSize, offerIds: filters.offerIds })
  useEffect(() => {
    canRefetch && refetch()
  }, [canRefetch, refetch])

  return (
    <Stack height="100%" width="100%">
      <Stack flexDirection="column" gap={4}>
        <Table
          id="table"
          maxHeight="100%"
          headCells={getHeadCells()}
          onRequestSort={({ sort }) => {
            setOrderDirection(sort === "asc" ? OrderDirection.ASC : OrderDirection.DESC)
          }}
          bgHeadCell="#121212"
          minWidth={700}
          empty={canRenderEmptyState}
        >
          {isFetching
            ? times(pageSize, (idx) => <InvoiceTableLoading key={`skeleton-row-${idx}`} />)
            : (data?.items ?? []).map((invoice: Invoice) => {
                return (
                  <TableRow
                    key={invoice.id}
                    onClick={() => redirectToInvoiceDetail(invoice.id)}
                    style={{ cursor: "pointer" }}
                  >
                    <TableCell density="compact" padding="none" scope="row">
                      <Stack direction="row" alignItems="center" gap={2}>
                        {getIcon(invoice.paymentMethod)}
                        <Text variant="body1">{formatCurrency(invoice.totalCents / 100)}</Text>
                      </Stack>
                    </TableCell>
                    <TableCell density="compact" padding="none" scope="row">
                      <Stack direction="row" gap={1}>
                        <InvoiceStatusStats status={invoice.status} size="tiny" />
                        {invoice.smartInstallmentRef && (
                          <SmartInstallmentChip
                            status={invoice.smartInstallmentRef.status}
                            size="tiny"
                            installment={invoice.installment}
                            installments={invoice.installments}
                          />
                        )}
                      </Stack>
                    </TableCell>
                    <TableCell density="compact" padding="none" scope="row">
                      <Text color="outline" variant="body1" fontWeight="lighter">
                        {invoice.items[0]?.productName ?? ""}
                      </Text>
                    </TableCell>
                    <TableCell density="compact" padding="none" scope="row">
                      <Text color="outline" variant="body1" fontWeight="lighter">
                        {t(`userInvoices.invoiceType.${invoice.type}`)}
                      </Text>
                    </TableCell>
                    <TableCell density="compact" padding="none" scope="row">
                      <Text color="outline" variant="body1" fontWeight="lighter">
                        {moment(invoice.createdAt).format("D MMM. YYYY")}
                      </Text>
                    </TableCell>
                  </TableRow>
                )
              })}
        </Table>

        <Pagination
          page={page}
          pageSize={pageSize}
          total={data.total}
          lastPage={data.lastPage}
          onChangePage={(page) => setPage(page)}
          onChangePageSize={(size) => setPageSize(size)}
        />
      </Stack>
      <Snackbar open={!!error} variant="negative" closeable={false}>
        {t((error as Error)?.message) ?? ""}
      </Snackbar>
    </Stack>
  )
}
