import { Document, Interface } from "@chatpay/common"
import { Order, Where } from "Service/DB"
import { DB } from "../Service"
import API from "./API"
import firebase from "firebase/compat/app"
import "firebase/compat/storage"

const FETCH_LIMIT = 20

class GroupMember extends API implements Interface.Group.Member.Function.ITemplate {
  private db = new DB(Document.GroupMember)
  private dbGroup = new DB(Document.Group)

  public async cancel(
    data: Interface.Group.Member.Function.Params.Cancel,
  ): Interface.Group.Member.Function.Result.Cancel {
    const response = await this.call("cancel", data)
    return new Document.Member(response.data)
  }

  private async call(func: Interface.Group.Member.Function.Name, params?: Interface.Group.Member.Function.Params.All) {
    return await this.callFunction(`groupMember/${func}`, params)
  }

  // QUERIES
  public async fetch(
    filter?: { by: "group.id" | "user.ims.whatsapp" | "user.ims.telegram"; value: string },
    limit: number = FETCH_LIMIT,
    startAfter?: firebase.firestore.Timestamp,
    startDate?: Date,
    endDate?: Date,
    orderDirection: "desc" | "asc" = "desc",
    status?: Document.GroupMemberStatus,
  ): Promise<Document.GroupMember[]> {
    const currentUserId = API.currentRoleplayUserId ?? API.currentUser?.id
    const whereData: Where[] = [{ field: "group.owner", op: "==", value: currentUserId }]

    if (status) {
      whereData.push({ field: "status", op: "==", value: status })
    }

    if (filter) {
      whereData.push({ field: filter.by, op: "==", value: filter.value })
    }

    if (startDate) {
      whereData.push({ field: "lastActivity.timestamp", op: ">=", value: DB.makeTimestamp(startDate) })
    }

    if (endDate) {
      whereData.push({ field: "lastActivity.timestamp", op: "<=", value: DB.makeTimestamp(endDate) })
    }

    const order: Order[] = [{ by: "lastActivity.timestamp", direction: orderDirection }]

    return await this.db.get({ where: whereData, pagination: { limit, startAfter }, order })
  }

  public async getById(id: string, include: string[] = []): Promise<Document.GroupMember | null> {
    const groupMember = await this.db.getById(id, include)
    return groupMember?.isDeleted ? null : groupMember
  }

  public async myMemberOf(groupId: string): Promise<Document.Member | null> {
    if (!API.currentUser?.id) {
      return null
    }
    try {
      return await this.dbGroup.getSubCollectionById(Document.Member, API.currentUser.id, groupId)
    } catch (e: any) {
      if (e.message?.includes("Missing or insufficient permissions")) {
        return null
      }
      throw e
    }
  }
}

export default GroupMember
