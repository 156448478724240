import { useContainer } from "@hub-la/fe-container"
import { HttpClient } from "@hub-la/fe-core-http-client"
import { useQuery } from "react-query"
import { GetSubscription } from "../../usecases/get-subscription"
import { QueryKey } from "modules/user-subscriptions/domain/enums/query-key"

export const useGetSubscription = (id: string) => {
  const container = useContainer()

  return useQuery([QueryKey.getSubscription, id], () => new GetSubscription(container.get(HttpClient)).execute(id), {
    refetchOnWindowFocus: false,
    retry: false,
    enabled: !!id,
  })
}
