import { Document } from "@chatpay/common"
import { API } from "@chatpay/components"
import { AnyAction } from "redux"
import { ThunkAction } from "redux-thunk"

export enum AffiliateActionTypes {
  LOAD = "AFFILIATE_LOAD",
  LOAD_SUCCESS = "AFFILIATE_LOAD_SUCCESS",
  LOAD_FAILURE = "AFFILIATE_LOAD_FAILURE",
}

export interface ILoadAction {
  type: AffiliateActionTypes.LOAD
}

export interface ILoadSuccessAction {
  type: AffiliateActionTypes.LOAD_SUCCESS
  myAffiliates: Document.Affiliate[]
}

export interface ILoadFailureAction {
  type: AffiliateActionTypes.LOAD_FAILURE
  reason: string
}

export const load = (): ThunkAction<Promise<void>, {}, {}, AnyAction> => async (dispatch) => {
  dispatch({
    type: AffiliateActionTypes.LOAD,
  })
  try {
    const affiliates = await new API.Affiliate().getAffiliates()
    const myAffiliatesSorted = (affiliates ?? []).sort((a, b) => b?.createdAt?.toMillis()! - a?.createdAt?.toMillis()!)
    dispatch(loadSuccess(myAffiliatesSorted!))
  } catch (e) {
    dispatch(loadFailure(String(e)))
  }
}

export const loadSuccess = (myAffiliates: Document.Affiliate[]): ILoadSuccessAction => {
  return {
    type: AffiliateActionTypes.LOAD_SUCCESS,
    myAffiliates,
  }
}

export const loadFailure = (reason: string): ILoadFailureAction => {
  return {
    type: AffiliateActionTypes.LOAD_FAILURE,
    reason,
  }
}

export type AffiliateAction = ILoadAction | ILoadSuccessAction | ILoadFailureAction
