import { Document, Events, Interface } from "@chatpay/common"
import { API, Analytics, Service } from "@chatpay/components"
import {
  Button,
  Modal,
  ModalActions,
  ModalDescription,
  ModalTitle,
  Stack,
  Text,
  ThemeProvider,
  theme,
  useMediaQuery,
} from "@hub-la/design-system"
import { DotsLoading } from "components"
import { useShowMessageActivateSalesResourceType } from "modules/product-header/presentation/hooks/use-show-message-activate-sales-resource-type"
import React, { useCallback, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { getGroupUrl } from "utils/SessionUtils"
import isSellingIcon from "../../../assets/icons/waitlist/isSelling.svg"
import isWaitlistedIcon from "../../../assets/icons/waitlist/isWaitlisted.svg"
import { Option } from "./Option"

type Props = {
  product: Document.Product
  group: Document.Group
  statusChanged: () => any
  originPage: GroupPublishButtonOriginPage
  closed?: () => any
  justModal?: boolean
  isPublish?: boolean
  isWaitlisted?: boolean
  resourceDisabled?: string
  fetchData?: () => void
}

export enum GroupPublishButtonOriginPage {
  productsPageSalesActivationButton = "productsPageSalesActivationButton",
  productStatusButton = "productStatusButton",
  boxAlert = "boxAlert",
}

export const GroupPublishButton: React.FC<Props> = (props) => {
  const { product, group, statusChanged, closed, justModal, isPublish, isWaitlisted, resourceDisabled, fetchData } =
    props
  const [selectedStatus, setSelectedStatus] = useState<Document.GroupStepStatus>()
  const [error, setError] = useState<Error>()
  const [open, setOpen] = useState<boolean>(justModal ? true : false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [succeeded, setSucceeded] = useState<boolean>(false)

  const { t } = useTranslation()
  const isDesktop = useMediaQuery("(min-width: 768px)")
  const showMessageActivateSalesResourceType = useShowMessageActivateSalesResourceType({ group, selectedStatus })

  const getResourceTypeName = (groupResourceType: Document.ResourceType) => {
    switch (groupResourceType) {
      case Document.ResourceType.whatsapp:
        return Interface.Resource.IResourceName.whatsapp
      case Document.ResourceType.telegram:
      case Document.ResourceType.telegram_channel:
        return Interface.Resource.IResourceName.telegram
    }
  }

  const getResourceType = (groupResourceType: Document.ResourceType) => {
    switch (groupResourceType) {
      case Document.ResourceType.whatsapp:
      case Document.ResourceType.telegram:
        return Interface.Resource.IResourceType.group
      case Document.ResourceType.telegram_channel:
        return Interface.Resource.IResourceType.channel
    }
  }

  const onSubmit = useCallback(async () => {
    if (!selectedStatus) {
      return
    }

    if (selectedStatus === Document.GroupStepStatus.isWaitlisted) {
      Analytics.track(Events.CREATOR_DASHBOARD.GROUP_STATUS_SUMITTED, {
        groupId: group.id,
        creatorId: group.owner?.id,
        resourceType: group.groupResourcesType,
        productType: product.type,
        newStatus: selectedStatus,
      })
    }

    setIsLoading(true)

    //  start selling
    if (selectedStatus === Document.GroupStepStatus.isSelling) {
      // publish product
      if (product.groupsId?.length === 1) {
        try {
          localStorage.setItem(group.productId, new Date().toString())

          await new API.Product().publish({
            id: group.productId,
            status: Interface.Product.Status.ready,
          })
        } catch (error) {
          setIsLoading(false)
          setError(error as Error)
          return false
        }
      }
    }

    // update status group
    try {
      let changeStatusData: Interface.Group.Function.IChangeStatus = {
        groupId: group.id,
        status: selectedStatus,
      }
      await new API.Group().changeStatus(changeStatusData)
      setSucceeded(true)
    } catch (e: any) {
      console.error(e)
      setError(e)
    } finally {
      setIsLoading(false)
    }
  }, [group.groupResourcesType, group.id, group.owner?.id, group.productId, product, selectedStatus])

  const handleModalClose = useCallback(() => {
    setSelectedStatus(undefined)
    setError(undefined)
    setOpen(false)
    closed && closed()
    setIsLoading(false)
    setSucceeded(false)
    fetchData && fetchData()
  }, [closed, fetchData])

  const handleSubmit = useCallback(async () => {
    if (resourceDisabled && selectedStatus === Document.GroupStepStatus.isSelling) {
      handleModalClose()
      return
    }
    onSubmit()
  }, [handleModalClose, onSubmit, resourceDisabled, selectedStatus])

  const onFinish = async () => {
    setOpen(false)
    closed && closed()
    statusChanged()
    if (selectedStatus === Document.GroupStepStatus.isSelling) {
      window.open(getGroupUrl(group.id), "_blank")
    }
  }

  // Modal control to skip product activation or waiting list.
  // These props are sent by the GroupStatusButton
  useEffect(() => {
    if (justModal) {
      if (isWaitlisted) {
        setSelectedStatus(Document.GroupStepStatus.isWaitlisted)
      }
      if (isPublish) {
        setSelectedStatus(Document.GroupStepStatus.isSelling)
      }
      handleSubmit()
    }
  }, [justModal, isPublish, isWaitlisted, handleSubmit])

  if (succeeded) {
    return (
      <ThemeProvider theme={theme.light}>
        <Modal
          size={isDesktop ? "medium" : "full"}
          close={
            (selectedStatus! && selectedStatus === Document.GroupStepStatus.isSelling) ||
            (selectedStatus! && selectedStatus === Document.GroupStepStatus.isWaitlisted)
              ? handleModalClose
              : undefined
          }
          open
        >
          <ModalDescription>
            <Stack width="100%" direction="column" spacing={3}>
              <img
                alt="community"
                src={require("assets/images/Community.png")}
                style={{ width: "140px", alignSelf: "center" }}
              />
              <Text variant="h3">
                {selectedStatus === Document.GroupStepStatus.isSelling
                  ? t("editGroup.publishButton.activeSales")
                  : t("editGroup.publishButton.activeWaitlist")}
              </Text>
              <Text>
                {selectedStatus === Document.GroupStepStatus.isSelling
                  ? t("editGroup.publishButton.doneSales")
                  : t("editGroup.publishButton.doneWaitlist")}
              </Text>
              <Button
                data-testid={"publish-group-button"}
                hierarchy="primary"
                variant="filled"
                onClick={onFinish}
                loading={isLoading}
              >
                {selectedStatus === Document.GroupStepStatus.isSelling
                  ? t("editGroup.publishButton.buttonAccessProductPage")
                  : t("editGroup.publishButton.buttonWaitlist")}
              </Button>
            </Stack>
          </ModalDescription>
        </Modal>
      </ThemeProvider>
    )
  }

  if (isLoading) {
    return (
      <ThemeProvider theme={theme.light}>
        <Modal size={isDesktop ? "medium" : "full"} disableEscapeKeyDown open>
          <ModalDescription>
            <Stack direction="column" spacing={3} paddingTop={7} width="100%" textAlign="center">
              <DotsLoading />
              <Text variant="h3">
                {selectedStatus === Document.GroupStepStatus.isSelling
                  ? t("editGroup.publishButton.activateSales")
                  : t("editGroup.publishButton.activateWaitList")}
              </Text>
              {showMessageActivateSalesResourceType && (
                <Text>
                  {t("editGroup.publishButton.activateSalesResourceType", {
                    resourceType: t(
                      `editGroup.publishButton.activateSalesResourceTypeLabel.${getResourceType(
                        group.groupResourcesType[0],
                      )}`,
                    ),
                    resourceName: getResourceTypeName(group.groupResourcesType[0]),
                  })}
                </Text>
              )}
            </Stack>
          </ModalDescription>
        </Modal>
      </ThemeProvider>
    )
  }

  return (
    <ThemeProvider theme={theme.light}>
      <Modal size={isDesktop ? "medium" : "full"} close={handleModalClose} open={open}>
        <React.Fragment>
          <ModalTitle>
            <Text paddingTop={7} variant="h3">
              {t("editGroup.publishButton.activateModalTitle")}
            </Text>
          </ModalTitle>
          <ModalDescription>
            <Stack direction="column" spacing={2}>
              <Text paddingBottom={4} variant="body2">
                {t("editGroup.publishButton.activateModalSubtitle")}
              </Text>
              <Option
                data-testid={"publish-group-sales-button"}
                active={selectedStatus! && selectedStatus === Document.GroupStepStatus.isSelling}
                onClick={() => setSelectedStatus(Document.GroupStepStatus.isSelling)}
                title={t("editGroup.publishButton.salesOptionTitle")}
                text={t("editGroup.publishButton.salesOptionSubtitle")}
                icon={isSellingIcon}
              />
              <Option
                data-testid={"publish-group-waitlist-button"}
                active={selectedStatus! && selectedStatus === Document.GroupStepStatus.isWaitlisted}
                onClick={() => setSelectedStatus(Document.GroupStepStatus.isWaitlisted)}
                title={t("editGroup.publishButton.waitlistOptionTitle")}
                text={t("editGroup.publishButton.waitlistOptionSibtitle")}
                icon={isWaitlistedIcon}
              />
              <Service.ErrorMessage error={error} />
            </Stack>
          </ModalDescription>
          <ModalActions align="right">
            <Button marginRight={4} hierarchy="primary" variant="text" onClick={handleModalClose}>
              {t("editGroup.publishButton.maybeLater")}
            </Button>
            <Button
              disabled={!selectedStatus}
              data-testid="submit-group-publish"
              hierarchy="primary"
              variant="filled"
              onClick={handleSubmit}
            >
              {t("editGroup.publishButton.activateMyProduct")}
            </Button>
          </ModalActions>
        </React.Fragment>
      </Modal>
    </ThemeProvider>
  )
}
