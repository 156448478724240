"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Name = exports.Membership = void 0;
var Membership;
exports.Membership = Membership;

(function (Membership) {
  Membership["ADMIN"] = "admin";
  Membership["MEMBER"] = "member";
  Membership["NOT_A_MEMBER"] = "not_a_member";
  Membership["WHITELIST"] = "whitelist";
  Membership["BLACKLIST"] = "blacklist";
  Membership["NOT_ALLOWED"] = "not_allowed";
})(Membership || (exports.Membership = Membership = {}));

var Name;
exports.Name = Name;

(function (Name) {
  Name["get"] = "get";
  Name["getGroupDetail"] = "getGroupDetail";
  Name["setInfo"] = "setInfo";
  Name["setResourceTypesIds"] = "setResourceTypesIds";
  Name["setDescription"] = "setDescription";
  Name["setAboutDescription"] = "setAboutDescription";
  Name["setAboutAdvantages"] = "setAboutAdvantages";
  Name["setAboutFaq"] = "setAboutFaq";
  Name["setAboutAssets"] = "setAboutAssets";
  Name["setPrice"] = "setPrice";
  Name["setEntryFee"] = "setEntryFee";
  Name["setLinkedGroups"] = "setLinkedGroups";
  Name["getLinkedGroups"] = "getLinkedGroups";
  Name["setPixel"] = "setPixel";
  Name["setAffiliate"] = "setAffiliate";
  Name["setWelcome"] = "setWelcome";
  Name["getMemberships"] = "getMemberships";
  Name["myGroups"] = "myGroups";
  Name["myGroupsBFF"] = "myGroupsBFF";
  Name["myPurchasedGroups"] = "myPurchasedGroups";
  Name["getParticipants"] = "getParticipants";
  Name["setFlags"] = "setFlags";
  Name["migrateGroup"] = "migrateGroup";
  Name["updateLink"] = "updateLink";
  Name["create"] = "create";
  Name["update"] = "update";
  Name["adminUpdate"] = "adminUpdate";
  Name["publish"] = "publish";
  Name["saveOrder"] = "saveOrder";
  Name["checkLink"] = "checkLink";
  Name["verifyDomain"] = "verifyDomain";
  Name["sendMigrationRequestToSlack"] = "sendMigrationRequestToSlack";
  Name["setTaxInvoiceChoice"] = "setTaxInvoiceChoice";
  Name["getGroupResourceAndResource"] = "getGroupResourceAndResource";
  Name["changeStatus"] = "changeStatus";
  Name["myGroupsAsOwnerOrCocreator"] = "myGroupsAsOwnerOrCocreator";
  Name["setBuyCta"] = "setBuyCta";
  Name["getShortLinks"] = "getShortLinks";
  Name["createShortLink"] = "createShortLink";
  Name["updateShortLink"] = "updateShortLink";
  Name["deleteShortLink"] = "deleteShortLink";
  Name["setCheckoutSettings"] = "setCheckoutSettings";
  Name["setSmartInstallmentsSettings"] = "setSmartInstallmentsSettings";
})(Name || (exports.Name = Name = {}));